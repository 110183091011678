import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {
  ADMIN_CALCULATE_PAGE,
  ADMIN_REPORT_PAGE, ADMIN_REPORT_PAGE2,
  ADMIN_REQUEST_MANAGEMENT_PAGE,
  ALL_CUSTOMER_MANAGEMENT_PAGE, ALL_STORE_LIST_MANAGEMENT_PAGE,
  BOARD_DETAIL_PAGE,
  BOARD_LIST_PAGE,
  BOARD_WRITE_PAGE, DEVELOPER_MANAGEMENT_PAGE, DEVELOPER_MONITORING_PAGE, DEVELOPMENT_HISTORY_PAGE,
  EXCEL_LOAD_PAGE,
  FAQ_PAGE,
  FORBIDDEN_PAGE,
  GAME_MANAGEMENT_PAGE,
  GAME_ROUND_MANAGEMENT_PAGE,
  LOGIN_PAGE,
  MAIN_PAGE, MAIN_PAGE_TEMP, MONITORING_STORE_PAGE,
  MY_CART_PAGE,
  MY_INFO_PAGE, MY_SETTING_PAGE,
  MY_STORE_INFO_PAGE,
  MY_STORE_LIST_PAGE,
  REQUEST_LIST_PAGE,
  SIGNUP_PAGE, STAFF_MANAGEMENT_PAGE,
  STORE_CUSTOMER_MANAGEMENT_PAGE,
  STORE_MANAGEMENT_PAGE,
  STORE_REPORT_PAGE,
  STORE_REQUEST_LIST_PAGE, TICKET_PRINT_PAGE, UNREGISTER_PAGE
} from "./constants/page_constants";
import SignUpPage from "./pages/SignUpPage";
import MainPage from "./pages/MainPage";
import MyInfoPage from "./pages/MyInfoPage";
import MyFavoriteStoreListPage from "./pages/MyFavoriteStoreListPage";
import FAQPage from "./pages/FAQPage";
import MyGameCartPage from "./pages/ticketListPage/MyGameCartPage";
import LoginPage from "./pages/LoginPage";
import RequestListPage from "./pages/ticketListPage/RequestListPage";
import MyStoreInfoPage from "./pages/storePage/MyStoreInfoPage";
import AllCustomerManagementPage from "./pages/adminPage/AllCustomerManagementPage";
import StaffManagementPage from "./pages/storePage/StaffManagementPage";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreRequestListPage from "./pages/ticketListPage/StoreRequestListPage";
import CustomerReportPage from "./pages/storePage/CustomerReportPage";
import React, {useEffect, useState} from "react";
import {SoundOption, SoundPlayer, SoundPlayerHandle} from "./utils/Soundplayer";
import BoardDetailPage from "./pages/board/BoardDetailPage";
import AllStoreListManagementPage from "./pages/adminPage/AllStoreListManagementPage";
import StoreManagementPage from "./pages/adminPage/StoreManagementPage";
import BoardWritePage from "./pages/board/BoardWritePage";
import BoardListPage from "./pages/board/BoardListPage";
import ExcelLoadPage from "./pages/ExcelLoadPage";
import StoreCustomerManagementPage from "./pages/storePage/StoreCustomerManagementPage";
import GameRoundManagementPage from "./pages/adminPage/GameRoundManagementPage";
import GameManagementPage from "./pages/adminPage/GameManagementPage";
import AdminRequestListPage from "./pages/ticketListPage/AdminRequestListPage";
import AdminReportPage from "./pages/adminPage/AdminReportPage";
import WindowInterfaces, {setStorePrintHandler} from "./pages/WindowInterfaces";
import {getRegisteredMenus} from "./constants/component_data";
import MySettingPage from "./pages/MySettingPage";
import UnregisterPage from "./pages/UnregisterPage";
import neverLandNotificationInterface from "./utils/NeverLandNotificationInterface";
import {accountInfo} from "./constants/constants";
import {updateMyInfo} from "./utils/NeverLandRestFULInterface";
import {customToast, NoticeModalContainer} from "./utils/customToast";
import {getFetcher} from "./utils/fetcher";
import {CHANGE_GAME_STATE_TO_PRINT_DONE_API} from "./constants/api_constants";
import AdminCalculatePage from "./pages/adminPage/AdminCalculatePage";
import DevelopmentHistoryPage from "./pages/adminPage/DevelopmentHistoryPage";
import DirectMessageContainer from "./components/DirectMessageContainer";
import MainNoticeModal from "./components/Modal/MainNoticeModal";
import neverLandUtils from "./utils/NeverLandUtils";
import DeveloperManagementPage from "./pages/developerPage/DeveloperManagementPage";
import DeveloperMonitoringPage from "./pages/developerPage/DeveloperMonitoringPage";
import ForbidPage from "./pages/ForbidPage";
import MonitoringStorePage from "./pages/adminPage/MonitoringStorePage";
import TicketPrintPage from "./pages/ticketListPage/TicketPrintPage";

function updateMenuSelections(path, navi) {
  // url 주소에 따른 사이드바, 헤더 현재 페이지 current 속성 true
  let menuList = getRegisteredMenus();
  path = path + "|";

  if (menuList && menuList.length > 0) {
    let prevMenuList = menuList.find((menu) => menu.current);
    let navigationMessage = null;
    menuList.forEach(menu => {
      let temp = menu.link;
      let param = menu.parameter;
      if (param) {
        temp += "/" + param;
      }
      temp = temp + "|";
      menu.current = temp.includes(path);
      if (menu.current) {
        if (menu.name.endsWith("!")) {
          navigationMessage = '현재 준비중인 기능입니다.';
          navi(-1);
        }
        if (menu.notifyKey) {
          neverLandNotificationInterface.commitNotifications(menu.notifyKey);
        }
      }
    });
    if (navigationMessage !== null) {
      toast.info('현재 준비중인 기능입니다.');
    }
  }
}

function _checkSoundOption(target) {
  // 내 알람 설정
  if (SoundOption.all_mute === 'true')
    return false;
  if (accountInfo.getUserConfig().isOn('on_mute', false))
    return false;
  let keyMapping = [
    ["order_list", "on_request_print"],
    ["response_confirm_of_my_store", "on_store_confirm"],
    ["order_list_for_store", "on_request_register"],
    ["request_confirm_for_store_owner", "on_confirm_request"],
    ["on_game_result", "on_game_result"]
  ];

  for (let data of keyMapping) {
    if (target.includes("notifyKey:" + data[0])) {
      if (accountInfo.getUserConfig().isOn(data[1], false)) {
        return true;
      }
      return false;
    }
  }
  return true;
}

// function sendDirectMessage(paramJson) {
//
// }

function App() {
  const [is_first_navigation, setIs_first_navigation] = useState(false);
  const navi = useNavigate();
  const soundPlayer = new SoundPlayerHandle();
  const path = useLocation().pathname;
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);

  neverLandNotificationInterface.playSound = () => soundPlayer.play("noti", "noti");
  neverLandNotificationInterface.checkSoundOption = (target) => _checkSoundOption(target);
  neverLandNotificationInterface.event.onRelogin = () => {
    updateMyInfo(navi)
  };
  // neverLandDirectMessage.sendDirectMessageInterface = (paramJson) => {
  //   sendDirectMessage(paramJson)
  // };

  neverLandUtils.events.onLogout=(message)=>{
    navi(MAIN_PAGE);
    toast.success(message);
  };

  setStorePrintHandler((param, refresh) => {
    if (param.includes('printing') && param.includes('done')) {

      let plist = param.split('&');
      let ticket = null;
      for (let temp of plist) {
        if (temp.includes("idList=")) {
          ticket = temp.split("=")[1].replaceAll("'", "").split("▼");
        }
      }

      if (ticket !== null) {
        // 지점에서 처리상태 변경 (인쇄완료)
        getFetcher().post(CHANGE_GAME_STATE_TO_PRINT_DONE_API, {
          user: accountInfo.makeJson(),
          gameDataIdList: ticket,
        }).then((res) => {
          const header = res.data.header;
          if (header.type === "success") {
            customToast.success("request", "출력 처리", header.message);
            if (refresh) {
              refresh();
            }
          } else {
            toast.error("변경이 실패하였습니다.");
          }
        }).catch(err => toast.error(err.message));
      }
    }
  })

  const notiDataToLocal = neverLandUtils.commons.load('noti_modal');
  const checkShowNoticeModal = () => {
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD 형식으로 반환
    // 로그인 안했으면 공지 모달 안보이게
    if (!accountInfo.isLogin()) {
      setNoticeModalOpen(true);
      return
    }
    // 로컬에 isOpen:false -> 팝업 안띄우기
    // lastShownDate(팝업 닫은 날짜)가 오늘이 아니면 isOpen:true 변경해서 보이기
    if (notiDataToLocal.lastShownDate !== today) {
      setNoticeModalOpen(true);
      const updatedData = {
        ...notiDataToLocal,
        isOpen: true
      };
      try {
        neverLandUtils.commons.save('noti_modal', updatedData);
      } catch (e) {
        console.error('Error saving to localStorage:', e);
      }
    }
    if (neverLandUtils.commons.load('noti_modal').isOpen === false) {
      setNoticeModalOpen(false);
    }
    return false;
  }

  // 점검 후 정상화되면 isSeverModification = false 해야됨
  const isSeverModification = false;

  useEffect(() => {
    if (isSeverModification) {
      return;
    }
    // 로컬 스토리지에서 알림창 데이터 불러오기
    // 로컬 스토리지에서 알림창 데이터 없으면 저장
    if (notiDataToLocal === null) {
      neverLandUtils.commons.save('noti_modal', {isOpen: true});
      setNoticeModalOpen(true);
    } else {
      // 있으면 체크해서 띄울지 정하기
      checkShowNoticeModal();
    }
  }, []);

  useEffect(() => {
    if (isSeverModification) {
      return;
    }
    // 로그인한 상태일때 저장해둔 마지막 페이지 위치로 이동
    neverLandNotificationInterface.start();

    if (path === MAIN_PAGE) {
      if (accountInfo.isLogin()) {
        if (!is_first_navigation) {
          let lastPath = accountInfo.loadLastNavigation();
          accountInfo.saveLastNavigation(null);
          if (lastPath !== null || lastPath !== path) {
            setIs_first_navigation(true);
            navi(lastPath);
            return;
          }
        }
        setIs_first_navigation(true);
      }
    }

    return () => {
      neverLandNotificationInterface.destroy();
    };
  }, []);

  // 점검중일때 점검중 페이지 띄우기
  if (isSeverModification) {
    if (path !== FORBIDDEN_PAGE)
      setTimeout(() => {
        navi(FORBIDDEN_PAGE);
      }, 10)
  } else {
    updateMenuSelections(path, navi);
    // 로그인한 상태일때 마지막 페이지 위치 저장
    if (accountInfo.isLogin() && path !== LOGIN_PAGE && !path.includes(TICKET_PRINT_PAGE)) {
      if (!is_first_navigation) {
        if (path !== MAIN_PAGE) {
          accountInfo.saveLastNavigation(path);
        }
      } else {
        accountInfo.saveLastNavigation(path);
      }
    }
  }


  return (
      <>
        {noticeModalOpen && <MainNoticeModal setShowAddModal={setNoticeModalOpen}/>}
        <WindowInterfaces/>
        <Routes>
          {/* <-- 공통 페이지 --> */}
          <Route path={LOGIN_PAGE} element={<LoginPage/>}/>
          <Route path={SIGNUP_PAGE} element={<SignUpPage/>}/>
          <Route path={UNREGISTER_PAGE} element={<UnregisterPage/>}/>
          <Route path={MAIN_PAGE} element={<MainPage/>}/>
          <Route path={MAIN_PAGE_TEMP} element={<MainPage/>}/>
          <Route path={MY_CART_PAGE} element={<MyGameCartPage/>}/>
          <Route path={REQUEST_LIST_PAGE} element={<RequestListPage/>}/>
          <Route path={MY_INFO_PAGE} element={<MyInfoPage/>}/>
          <Route path={MY_STORE_LIST_PAGE} element={<MyFavoriteStoreListPage/>}/>
          <Route path={MY_SETTING_PAGE} element={<MySettingPage/>}/>

          {/* <-- 지점 페이지 --> */}
          <Route path={STORE_REQUEST_LIST_PAGE} element={<StoreRequestListPage/>}/>
          <Route path={MY_STORE_INFO_PAGE} element={<MyStoreInfoPage/>}/>
          <Route path={STAFF_MANAGEMENT_PAGE} element={<StaffManagementPage/>}/>
          <Route path={STORE_CUSTOMER_MANAGEMENT_PAGE} element={<StoreCustomerManagementPage/>}/>
          <Route path={STORE_REPORT_PAGE} element={<CustomerReportPage/>}/>

          {/* <-- 관리자 페이지 --> */}
          <Route path={ALL_STORE_LIST_MANAGEMENT_PAGE} element={<AllStoreListManagementPage/>}/>
          <Route path={`${STORE_MANAGEMENT_PAGE}/:paramStoreCode?`} element={<StoreManagementPage/>}/>
          <Route path={ALL_CUSTOMER_MANAGEMENT_PAGE} element={<AllCustomerManagementPage/>}/>
          <Route path={GAME_ROUND_MANAGEMENT_PAGE} element={<GameRoundManagementPage/>}/>
          <Route path={GAME_MANAGEMENT_PAGE} element={<GameManagementPage/>}/>
          <Route path={ADMIN_REQUEST_MANAGEMENT_PAGE} element={<AdminRequestListPage/>}/>
          <Route path={ADMIN_REPORT_PAGE} element={<AdminReportPage/>}/>
          <Route path={ADMIN_REPORT_PAGE2} element={<AdminReportPage/>}/>
          <Route path={DEVELOPMENT_HISTORY_PAGE}
                 element={<DevelopmentHistoryPage setNoticeModalOpen={setNoticeModalOpen}/>}/>
          <Route path={ADMIN_CALCULATE_PAGE} element={<AdminCalculatePage/>}/>
          <Route path={MONITORING_STORE_PAGE} element={<MonitoringStorePage/>}/>

          {/* <-- 개발자 페이지 --> */}
          <Route path={DEVELOPER_MANAGEMENT_PAGE} element={<DeveloperManagementPage/>}/>
          <Route path={DEVELOPER_MONITORING_PAGE} element={<DeveloperMonitoringPage/>}/>

          {/* <-- 게시판 --> */}
          <Route path={`${BOARD_LIST_PAGE}/:cateId`} element={<BoardListPage/>}/>
          <Route path={FAQ_PAGE} element={<FAQPage/>}/>
          <Route path={BOARD_WRITE_PAGE} element={<BoardWritePage/>}/>
          <Route path={`${BOARD_DETAIL_PAGE}/:boardId`} element={<BoardDetailPage/>}/>

          {/*접근제한 페이지*/}
          <Route path={FORBIDDEN_PAGE} element={<ForbidPage/>}/>
          {/*엑셀 읽어오는 페이지*/}
          <Route path={EXCEL_LOAD_PAGE} element={<ExcelLoadPage/>}/>
          {/*모아찍기 페이지*/}
          <Route path={TICKET_PRINT_PAGE + "/:requestNumber"} element={<TicketPrintPage/>}/>
        </Routes>

        {/*/!* 페이지 이동시 스크롤 최상단으로 유지 *!/*/}
        {/* 사이드바안에 스크롤 버튼으로 대체 */}
        {/*<ScrollToTop/>*/}

        {/* 시스템 소리 */}
        <SoundPlayer initPlayerHandle={soundPlayer}/>

        {/* NoticeModalContainer(customToast) 알림 */}
        <NoticeModalContainer/>

        {/* 토스트 알림 */}
        <ToastContainer
            onOpen={() => soundPlayer.play("toast/" + path, "toast")}
            position="top-center"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false} // 새로운 알람 위치 - false : 아래쪽
            closeOnClick  // 클릭으로 알람 off
            rtl={false} // 알람 좌우 반전 X
            pauseOnFocusLoss={false} // 화면을 벗어나면 알람 정지 X
            draggable
            pauseOnHover={false} // 마우스 호버 시 일시정지 X
            theme="colored"
        />

        {/* 받은 쪽지함 */}
        <DirectMessageContainer onNewMessageReceived={() => soundPlayer.play("message/" + path, 'confirm')}/>
      </>
  );
}

export default App;
