import React, {useEffect, useState} from "react";
import TicketAlignButtons from "../../../components/TicketAlignButtons";
import Pagination from "../../../components/Pagination/Pagination";
import {getFetcher} from "../../../utils/fetcher";
import {MY_GAME_DATA_API} from "../../../constants/api_constants";
import {accountInfo} from "../../../constants/constants";
import {toast} from "react-toastify";
import neverLandGameData from "../../../utils/NeverLandGameData";
import neverLandUtils from "../../../utils/NeverLandUtils";
import {RiFileListLine, RiFilePaper2Line} from "react-icons/ri";
import ListSelectBox from "../../../components/ListSelectBox";
import BaseTicketCard from "../../../components/Card/BaseTicketCard";
import ListSelectBoxWithDefaultData from "../../../components/ListSelectBoxWithDefaultData";
import TicketListTable from "../../../components/Table/TicketListTable";
import {MAIN_PAGE} from "../../../constants/page_constants";
import {useNavigate} from "react-router-dom";

export const roundDefaultList = [
  {id: 1, name: "현재 회차", data: {code: 'current'}},
]

export const roundInfo = {
  list: roundDefaultList
};

const GameResultState = [
  {name: "당낙상태 전체", conditon: null},
  {name: "당첨", conditon: "win"},
  {name: "낙첨", conditon: "lose"},
  {name: "적특", conditon: "reject"},
  {name: "진행중", conditon: "notyet"},
]


function getSearchConditionCode(key, value) {
  if (key === 'targetCode' && value === '?' && accountInfo.isStore()) {
    value = encodeURIComponent(accountInfo.getStoreCode());
  }
  return value;
}

function getActionButtonStyle(action) {
  // FIXME::일부 버튼 색상 적용 안됨 => 안되는 이유 모르겠음
  if (action.color === 'gray') {
    return `whitespace-nowrap mr-5 md:mr-2 lg:mb-0 rounded-md bg-gray-600 px-6 lg:px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 mb-3 md:mb-0`;
  }
  if (action.color === 'orange') {
    return `whitespace-nowrap mr-5 md:mr-2 lg:mb-0 rounded-md bg-orange-600 px-6 lg:px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 mb-3 md:mb-0`;
  }
  if (action.color === 'fuchsia') {
    return `whitespace-nowrap mr-5 md:mr-2 lg:mb-0 rounded-md bg-fuchsia-600 px-6 lg:px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-500 mb-3 md:mb-0`;
  }
  return `whitespace-nowrap mr-5 md:mr-2 lg:mb-0 rounded-md bg-${action.color}-600 px-6 lg:px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${action.color}-500 mb-3 md:mb-0`;
}

const BaseTicketListPage = ({
                              listKey,
                              isRequesting,
                              isShowFilter,
                              actionsJson,
                              searchInputOption,
                              ticketOrderType,
                              ticketState,
                              fixedFilter,
                              requestState,
                              refreshDate
                            }) => {
  const [gameDataList, setGameDataList] = useState([]);
  const [isTicketShow, setIsTicketShow] = useState(true);
  const [selectTicket, setSelectTicket] = useState({ticket: []});
  // 셀렉트박스
  const [selectedRound, setSelectedRound] = useState(roundDefaultList[0]); // 회차
  const [selectedRequestState, setSelectedRequestState] = useState(requestState && requestState.length > 0 ? requestState[0] : null); // 의뢰상태
  const [selectedGameResult, setSelectedGameResult] = useState(GameResultState[0]); // 당낙상태
  const [searchInput, setSearchInput] = useState(searchInputOption ? searchInputOption[0] : null); // 검색으로 찾기 셀렉트박스
  const [searchInputValue, setSearchInputValue] = useState(''); // 검색으로 찾기 인풋값
  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  let pageSize = isTicketShow ? 8 : 13;
  // 정렬 버튼 데이터
  const [alignData, setAlignData] = useState(accountInfo.getAlignDataList(listKey));

  const [refresh, onRefresh] = useState(refreshDate || new Date());

  useEffect(() => {
    getTicketDataList();
  }, [currentPage, alignData, isRequesting, refreshDate, selectedRound, selectedRequestState, selectedGameResult, isTicketShow, refresh]);

  useEffect(() => {
    if (!searchInput) return;
    if (searchInput.code === 'all') {
      getTicketDataList();
    }
  }, [searchInput]);

  const jsonDataToString = (obj) => {
    // <-- JSON 데이터 받아서 파라미터에 넣을 형식으로 변환하기 -->
    return Object.keys(obj).map(key => `${key}:${obj[key]}`).join('▼');
  }

  const getGameRound = (roundData) => {
    // 회차정보
    let round = roundData.substring(7);
    return roundData.substring(5, 7) + "년도 " + ((round.startsWith('0') ? round.substring(1) : round)) + " 회차";
  }


  const getTicketDataList = () => {
    // <-- 게임데이터 가져오기 -->
    let orderParams = jsonDataToString(alignData);
    let status = ticketState;
    let searchCondition = 'all';

    if (fixedFilter.searchCondition && fixedFilter.searchCondition.length > 0) {
      searchCondition = "";
      for (let condition of fixedFilter.searchCondition) {
        for (let [key, value] of Object.entries(condition)) {
          if (searchCondition.length > 0) {
            searchCondition += "▼";
          }
          searchCondition += key + ':' + getSearchConditionCode(key, value);
        }
      }
    }
    if (isShowFilter) {
      searchCondition += "▼gameRound:" + selectedRound.data.code;
      if (selectedRequestState)
        status += "▼" + selectedRequestState.code;

      if (selectedGameResult.conditon !== null) {
        searchCondition += "▼result:" + selectedGameResult.conditon;
      }
    }

    if (searchInputOption) {
      searchCondition += "▼item:" + searchInput.code + ":" + searchInputValue.trim();
    }
    getFetcher().get(accountInfo.makeGetRequest(MY_GAME_DATA_API)
        + `&status=${status}&searchCondition=${searchCondition}`
        + `&pageNumber=${currentPage}&pageSize=${pageSize}&searchOrder='${orderParams}'`)
        .then(res => {
          const {header, result, custom} = res.data;
          // 계정 차단 여부 확인
          if (neverLandUtils.security.checkLogout(res)) {
            return;
          }

          if (header.type === 'success') {
            let datalist = neverLandGameData.convertGameDataToTicket(result.gameDataList.content);
            let currentPageNumber = result.gameDataList.pageable.pageNumber + 1;
            if (datalist.length === 0) {
              currentPageNumber = 1;
            }
            setGameDataList(datalist);
            setCurrentPage(currentPageNumber);
            setTotalPage(result.gameDataList.totalPages);

            if (custom !== undefined && custom.items !== undefined && custom.items.length > 0) {

              for (let item of custom.items) {
                // 데이터에서 회차 셀렉트 리스트 받아와서 설정
                if (item.key === 'rounds') {
                  let newRoundList = [];
                  newRoundList.push(roundDefaultList[0]);

                  let index = 2;
                  for (let round of item.value) {
                    newRoundList.push({id: index++, name: getGameRound(round), data: {code: round}});
                  }
                  if (newRoundList.length > 0) {
                    roundInfo.list = newRoundList;
                    setSelectedRound(newRoundList[1]);
                  }
                  //item.value;
                }
              }
            }
          }
        }).catch(err => toast.error(err.message));
  }


  function onActionClick(action) {
    // <-- 기능 버튼 클릭 시 (ex : 출력의뢰, 복사의뢰, 요청 등) -->
    if (action === null || action.onClick === null || action.onClick === undefined) {
      toast.info('현재 준비중인 기능 입니다.');
      return;
    }
    if (selectTicket.ticket.length === 0) {
      toast.warn("선택한 티켓이 없습니다.");
      return;
    }
    if (action.name.includes('*')) {
      let data = gameDataList;
      if (data === null || data === undefined)
        return;

      let selectedTicketData = {};
      for (let id of selectTicket.ticket) {
        selectedTicketData[id] = getCurrentGameData(data, id);
      }
      action.onClick(selectTicket.ticket, selectedTicketData);
      if (cardHandler)
        cardHandler.clearSelection();
      return;
    }
    action.onClick(selectTicket.ticket);
    if (cardHandler)
      cardHandler.clearSelection();
  }

  function getCurrentGameData(data, id) {
    for (let game of data) {
      if (game.id === id) {
        return game;
      }
    }
    return undefined;
  }

  function onSelectedTickets(selectedTickets) {
    setSelectTicket({ticket: selectedTickets});
  }

  let isShort = !(ticketState.includes('!cart'));
  let cardHandler;

  return (
      <>
        <main className="lg:py-10 sm:px-5 px-3 lg:pl-64 bg-gray-100 min-h-screen">
          {/* TODO: isRequesting 일때 보이는 로딩화면 만들기 */}

          {/* <- 상단 버튼 라인 -> */}
          <div className="sm:px-10 flex flex-row flex-wrap w-full lg:flex-row items-center justify-between md:ml-5">

            <div className="flex flex-col justify-between w-full sm:w-auto lg:flex-row">
              {/* <- 조회 셀렉트 박스 -> */}
              {isShowFilter &&
                  <div className="flex flex-row flex-wrap justify-start">
                    {selectedRequestState &&
                        <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                                      onChange={setSelectedRound}/>}
                    {selectedRequestState &&
                        <ListSelectBox listData={requestState} onChange={setSelectedRequestState}/>}
                    {selectedRequestState &&
                        <ListSelectBox listData={GameResultState} onChange={setSelectedGameResult}/>}

                    {searchInputOption &&
                        <div className="flex flex-row">
                          <ListSelectBox listData={searchInputOption} onChange={(filter) => setSearchInput(filter)}/>
                          {searchInput.code !== 'all' &&
                              <>
                                <input
                                    className="mr-3 w-36 px-3 py-2 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500"
                                    onChange={(e) => setSearchInputValue(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') getTicketDataList();
                                    }}
                                />

                                <button onClick={getTicketDataList}
                                        className="whitespace-nowrap rounded-md bg-blue-900 px-3 mr-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800">
                                  검색
                                </button>
                              </>
                          }
                        </div>
                    }
                  </div>
              }

              {/* <- 액션 버튼 -> */}
              <div className={"flex flex-row flex-wrap items-center justify-start my-3 lg:my-0"}>
                {actionsJson.map((action) => {
                  let className = `whitespace-nowrap mr-2 lg:mb-0 rounded-md bg-${action.color}-600 px-3 lg:px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${action.color}-500`;
                  className = getActionButtonStyle(action);
                  return (
                      <button
                          key={action.name}
                          onClick={() => onActionClick(action)}
                          type="button"
                          className={className}
                      >
                        {action.name.replace('*', '')}
                      </button>
                  )
                })}
              </div>
            </div>

            {/* <- 화면 티켓 / 리스트 전환 버튼 & 데이터 정렬 버튼 -> */}
            <div className="flex flex-row">
              <RiFilePaper2Line onClick={() => setIsTicketShow(true)}
                                className={neverLandUtils.classNames(isTicketShow ? 'bg-blue-600' : 'bg-gray-400', 'h-10 w-10 text-white rounded-full px-2 ml-3')}/>
              <RiFileListLine onClick={() => setIsTicketShow(false)}
                              className={neverLandUtils.classNames(isTicketShow ? 'bg-gray-400' : 'bg-blue-600', 'h-10 w-10 text-white rounded-full px-2 ml-3')}/>

              <TicketAlignButtons currentAlignData={alignData} setAlignData={(newAlignData) => {
                accountInfo.saveAlignData(listKey, newAlignData);
                setAlignData(newAlignData);
              }}/>
            </div>

          </div>


          {!isShowFilter &&
              <p className="mt-5 text-gray-500 text-center"> ※ 저장 시점의 배당, 기준점과 차이가 있을 수 있으니 필히 확인바랍니다. </p>}

          {/* <- 게임데이터 화면 (티켓 / 리스트) -> */}
          {gameDataList !== undefined && gameDataList !== null && gameDataList.length !== 0 &&
              <div className={isShowFilter ? 'mt-5' : 'mt-1'}>
                {isTicketShow ?
                    // 티켓 형태로 보기
                    <div className="flex justify-center sm:min-h-[42rem] w-full">
                      <BaseTicketCard gameDataList={gameDataList}
                                      onSelectedTickets={onSelectedTickets}
                                      cardHandler={(handler) => {
                                        cardHandler = handler;
                                      }}
                                      onRefreshTicket={() => onRefresh(new Date())}
                                      ticketOrderType={ticketOrderType}
                                      ticketState={ticketState}
                      />
                    </div>
                    :
                    // 리스트 형식으로 보기
                    <div className="sm:min-h-[42rem]">
                      <TicketListTable isShort={isShort}
                                       gameDataList={gameDataList}
                                       onSelectedTickets={onSelectedTickets}
                                       ticketOrderType={ticketOrderType}
                                       ticketState={ticketState}
                                       cardHandler={(handler) => {
                                         cardHandler = handler;
                                       }}
                      />
                    </div>
                }

                {/* 페이지네이션 */}
                <div className="mt-6 lg:px-10">
                  <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={(page) => {
                        onSelectedTickets([]);
                        cardHandler.clearSelection();
                        setCurrentPage(page);
                      }}
                  />
                </div>
              </div>
          }
        </main>
      </>
  )
}
export default BaseTicketListPage;