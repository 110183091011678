//라이브 스코어 어플 알림 작업 -- 진짜용 live score 코드

import {accountInfo} from "../constants/constants";
import {GET_MY_NOTIFICATION_API} from "../constants/api_constants";
import {mobileInterface} from "../pages/WindowInterfaces";
import neverLandUtils from "./NeverLandUtils";
import {getFetcherNotification} from "./fetcher_notification";
import neverLandDirectMessage from "./NeverLandDirectMessage";

const _onNotificationMessagingEnd = (neverLandNotificationInterface, notificationActions) =>{
  if (notificationActions.includes('navigate:user/relogin')) {
    if (neverLandNotificationInterface.event.onRelogin) {
      neverLandNotificationInterface.event.onRelogin();
    }
  }
  if (notificationActions.includes('action:utils/reload')) {
      window.location.reload();
  }
}
const _onNotificationMessaging = (neverLandNotificationInterface, notificationAction, message, type) => {
  if (type === 'on-received') {
    return;
  }
  if (type === 'on-touch') {
    // toast.success(message);
    return;
  }
}

class NeverLandNotificationInterface {
  interval;
  playSound;
  checkSoundOption;

  event = {
    onRelogin: null
  };

  _notificationStatus=null;

  notificationData = null;

  onNotificationHandlers = [];

  _delay=3500;
  isSuspend=false;

  registMonitor(monitorInfo, onNotificationHandler) {
    let notificationTarget = "programKey:" + monitorInfo.programKey + "/accountKey:" + monitorInfo.accountKey + "/notifyKey:" + monitorInfo.notifyKey;
    let json = {
      onNotificationHandler: onNotificationHandler,
      monitorInfo: monitorInfo,
      notificationTarget: notificationTarget
    };
    this.onNotificationHandlers.push(json);

    if (this.notificationData !== null)
      this._isNotificationContains(json, this.notificationData.myNotificationList);
  }

  unRegistMonitor(onNotificationHandler) {
    this.onNotificationHandlers = this.onNotificationHandlers.filter((o) => o.onNotificationHandler !== onNotificationHandler);
  }

  destroy() {
    let _int = this.interval;
    this.interval = null;
    if (_int) {
      clearInterval(_int);
    }
  }

  //{programKey:programKey,notifyKey: notifyKey,accountKey: accountKey}

  _isNotificationContains(onNotificationHandler, thisList) {
    if (thisList.length === 0)
      return;

    let temp = {onNotificationHandler: onNotificationHandler.onNotificationHandler, list: []};
    for (let n of thisList) {
      if (n.notificationTarget === onNotificationHandler.notificationTarget) {
        temp.list.push(n);
      }
    }
    try {
      temp.onNotificationHandler(temp.list);
    } catch {
    }
  }

  _onNotificationChanged(newList, useAlarmSound) {
    if (newList.length === 0)
      return;

    if (useAlarmSound && this.playSound) {
      this.playSound();
    }

    let tempList = [];
    for (let onNotificationHandler of this.onNotificationHandlers) {
      let temp = {onNotificationHandler: onNotificationHandler.onNotificationHandler, list: []};
      for (let n of newList) {
        if (n.notificationTarget === onNotificationHandler.notificationTarget) {
          temp.list.push(n);
        }
      }
      if (temp.list.length > 0) {
        tempList.push(temp);
      }
    }
    if (tempList.length > 0) {
      for (let temp of tempList) {
        try {
          temp.onNotificationHandler(temp.list);
        } catch {
        }
      }
    }
  }
  constructor() {
    if (!NeverLandNotificationInterface.instance) {
      this._notificationStatus = {
        last_notification_time: 0
      };
      NeverLandNotificationInterface.instance = this;
    }
    return NeverLandNotificationInterface.instance;

  }


  commitNotifications(notifyKey) {
    // 눌렀을 때 알림 지우기
    if (!accountInfo.isLogin())
      return;
    if (this.notificationData === null) {
      this.notificationData = neverLandUtils.commons.load("notificationData");
    }
    if (this.notificationData === null) {
      this.notificationData = {myNotificationList: [], lastReceivedNotificationDate: 0};
    }
    notifyKey = "/notifyKey:" + notifyKey + "/";
    this.notificationData.myNotificationList = this.notificationData.myNotificationList.filter((o) => {
          if (!(o.notificationTarget + "/").includes(notifyKey)) {
            return true;
          }
          _onNotificationMessaging(this, o.notificationAction, o.notificationMessageDetails, 'on-touch');
          return false;
        }
    );

    for (let m of this.onNotificationHandlers) {
      if ((m.notificationTarget + "/").includes(notifyKey)) {
        try {
          m.onNotificationHandler([]);
        } catch {
        }
      }
    }
    neverLandUtils.commons.save("notificationData", this.notificationData);
  }

  getCurrentNotifications(currentJson) {
    if (this.notificationData === null)
      return [];
    let notificationTarget = "programKey:" + currentJson.programKey + "/accountKey:" + currentJson.accountKey + "/notifyKey:" + currentJson.notifyKey;
    let thisList = this.notificationData.myNotificationList;
    if (thisList.length === 0)
      return [];
    let temp = [];
    for (let n of thisList) {
      if (n.notificationTarget === notificationTarget) {
        temp.push(n);
      }
    }
    return temp;
  }

  onNotification(newNotificationJson) {
    if(newNotificationJson===null)
      return;
    let useAlarmSound = false;
    this.notificationData.lastReceivedNotificationDate = newNotificationJson.lastReceivedNotificationDate;
    let newList = [...this.notificationData.myNotificationList];

    function getTimeText(isoString) {
      const date = new Date(isoString);

      return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    }

    if (newNotificationJson.myNotificationList.length > 0) {
      for (let notificationData of newNotificationJson.myNotificationList) {
        if(notificationData.notificationMessageType==='direct-message'){
          let details=  notificationData.notificationMessageDetails.split('|');
          if(details.length>2){
            neverLandDirectMessage.appendNewMessage(details[0],details[1].replace('<sep/>','|'),getTimeText(details[2]));
          }
          continue;
        }
        newList.push(notificationData);
        if (this.checkSoundOption !== undefined) {
          if (this.checkSoundOption(notificationData.notificationTarget)) {
            useAlarmSound = true;
          }
        } else {
          useAlarmSound = true;
        }
      }
      this.notificationData.myNotificationList = newList;
      neverLandUtils.commons.save("notificationData", this.notificationData);
      this._onNotificationChanged(newList, useAlarmSound);
    }
  }

  start() {
    if (this.interval) {
      return;
    }
    setTimeout(() => {
      _onInterval();
      this.interval = setInterval(_onInterval, 1500)
    }, 1000);
  }

  getDelay() {
    return this._delay;
  }

  _mobileNotificationKey="";
  setNotificationKeyForApp(accountId, accountSessionCode) {
    let _notiKey="";
    if(accountId!==-1){
      let _lastReceivedNotificationDate=this.notificationData.lastReceivedNotificationDate;
      if(_lastReceivedNotificationDate===null){
        _lastReceivedNotificationDate=-1;
      }
      //"userId=23&sessionCode=1601:2128872655|lastReceivedNotificationDate"
      _notiKey="userId="+accountId+"&sessionCode="+accountSessionCode+"|"+_lastReceivedNotificationDate;
    }
    if(this._mobileNotificationKey===_notiKey){
      return;
    }
    this._mobileNotificationKey=_notiKey;
    mobileInterface.debug('setNotificationKeyForApp->'+_notiKey);
    mobileInterface.mobile_actionCommand("notificationKey", _notiKey);
  }



  pushNotificationsByScript(value) {
    console.log('pushNotificationsByScript',value);
    let _raw_data=getDataList(value);
    let json={
      result:{
        myNotificationList:[],
        lastReceivedNotificationDate:-1
      }
    };

    let lastReceivedNotificationDate=-1;
    for (let item of _raw_data) {
      let tempDate = item[1];
      json.result.myNotificationList.push({
        notificationTarget: item[0],
        registedNotificationDate: tempDate,
        notificationMessageType: item[2],
        notificationMessageDetails: item[3],
        notificationAction: item[4],
      });
      lastReceivedNotificationDate = Math.max(Number(tempDate)+1, lastReceivedNotificationDate);
    }
    json.result.lastReceivedNotificationDate=lastReceivedNotificationDate;
    neverLandNotificationInterface.onNotification(getNotificationJson(accountInfo.accountId(),json));
  }
}
function getDataList( currentValue) {
  let tokens = currentValue.split("\n");
  let result = [];
  let temp;

  for (let index = 0; index < tokens.length; index++) {
    temp=tokens[index].split("|");
    temp=encodeData(temp);
    result.push(temp);
  }
  return result;
}

function encodeData( tokens) {
  let temp;
  for (let index = 0; index < tokens.length; index++) {
    temp=tokens[index];
    temp=temp.replaceAll("<sep/>", "|").replaceAll("<br/>", "\n");
    tokens[index]=temp;
  }
  return tokens;
}
const _onInterval = () => {
  if(neverLandNotificationInterface._notificationStatus===null)
    return;
  let now=new Date().getTime();
  if(now-neverLandNotificationInterface._notificationStatus.last_notification_time<neverLandNotificationInterface.getDelay()){
    return;
  }
  neverLandNotificationInterface._notificationStatus.last_notification_time=now;
  try {
    getMyNotification();
  }catch{
  }
}


const getMyNotification = () => {
  if (!accountInfo.isLogin()) {
    neverLandNotificationInterface.notificationData = null;
    if(mobileInterface.isMobile()){
      neverLandNotificationInterface.setNotificationKeyForApp(-1);
    }
    return true;
  }
  if (neverLandNotificationInterface.notificationData === null) {
    neverLandNotificationInterface.notificationData = neverLandUtils.commons.load("notificationData");
  }
  if (neverLandNotificationInterface.notificationData === null) {
    neverLandNotificationInterface.notificationData = {myNotificationList: [], lastReceivedNotificationDate: 0};
  }
  if(mobileInterface.isMobile()){
    neverLandNotificationInterface.setNotificationKeyForApp(accountInfo.accountId(), accountInfo.accountSessionCode());
  }
  if(neverLandNotificationInterface.isSuspend){
    return;
  }
  // 내 알람정보 가져오기
  getFetcherNotification().get(accountInfo.makeGetRequest(GET_MY_NOTIFICATION_API) +
      `&lastReceivedNotificationDate=${neverLandNotificationInterface.notificationData.lastReceivedNotificationDate}`)
      .then((res) => {
        try {
          neverLandNotificationInterface.onNotification(getNotificationJson(accountInfo.accountId(), res.data));
        }catch(err){
          console.log("getMyNotification-->err1", err);
        }
      })
      .catch((err) => {
        console.log("getMyNotification-->err2", err);
      })
  return true;
}


const getNotificationJson = (accountKey, json) => {
  if(json.result.myNotificationList.length===0){
    neverLandNotificationInterface._delay= Math.min(5500,neverLandNotificationInterface._delay+350);
    return null;
  }
  neverLandNotificationInterface._delay=2500;
  // 실시간으로 알림 받기
  let result = {
    myNotificationList: json.result.myNotificationList
    , lastReceivedNotificationDate: json.result.lastReceivedNotificationDate
  };
  let actions=[];
  for (let noti of result.myNotificationList) {
    // WindowInterfaces.notificationTarget();
    // TODO: 토스트말고 화면에 메세지 띄우기
    // toast.success(noti.notificationMessageDetails);

    if(!actions.includes(noti.notificationAction)){
      actions.push(noti.notificationAction);
    }
    try {
      _onNotificationMessaging(neverLandNotificationInterface, noti.notificationAction, noti.notificationMessageDetails, 'on-received');
    } catch (err) {
      console.log("getNotificationJson-->err", err);
    }

    noti.notificationTarget = getNotificationTargetMapping(accountKey, noti.notificationTarget);
  }

  try {
    _onNotificationMessagingEnd(neverLandNotificationInterface, actions);
  } catch (err) {
    console.log("getNotificationJson-->err", err);
  }

  actions=null;

  if (mobileInterface.isMobile()) {
    let tempNotifications = {};

    for (let noti of result.myNotificationList) {
      tempNotifications[noti.notificationAction] = noti.notificationMessageDetails;
    }
    for (let [key, value] of Object.entries(tempNotifications)) {
      mobileInterface.mobile_notification(key, "normal", value);
    }
    tempNotifications = null;
  }
  return result;
}

const getNotificationTargetMapping = (accountKey, notificationTarget) => {
  if (notificationTarget.includes("store/customermanagement")) {
    return "programKey:mobydick/accountKey:" + accountKey + "/notifyKey:request_confirm_for_store_owner"
  }
  if (notificationTarget.includes("store/storerequestlist")) {
    return "programKey:mobydick/accountKey:" + accountKey + "/notifyKey:order_list_for_store"
  }
  if (notificationTarget.includes("user/requestlist")) {
    return "programKey:mobydick/accountKey:" + accountKey + "/notifyKey:order_list"
  }
  if (notificationTarget.includes("user/mystore")) {
    return "programKey:mobydick/accountKey:" + accountKey + "/notifyKey:response_confirm_of_my_store"
  }
  return notificationTarget;
}
const neverLandNotificationInterface = new NeverLandNotificationInterface();
export default neverLandNotificationInterface;

