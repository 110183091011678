import {TICKET_TABLE_HEADER_DEFAULT, TICKET_TABLE_HEADER_SHORT} from "../../constants/component_data";
import React, {useEffect, useState} from "react";
import TicketCardModal from "../Modal/TicketCardModal";
import {toast} from "react-toastify";
import neverLandUtils from "../../utils/NeverLandUtils";
import {CardHandlerInstance, getCardStateDisplayText} from "../Card/BaseTicketCard";
import ListSelectBox from "../ListSelectBox";
import {SHEET_QUANTITY} from "../../constants/component_data";

function getBadgeStateStyleText(state) {
  // 상태값 따라 배지 색상 className 반환
  if (state.includes('expired'))
    return 'bg-gray-200 text-gray-700 ring-gray-500';

  if (state === 'cart')
    return 'bg-blue-100 text-blue-600 ring-blue-500';

  return state.includes('abort') ? 'bg-red-300 text-red-700 ring-1 ring-red-500' : // 취소
      state.includes('request_ex') ? 'bg-blue-100 text-blue-600 ring-1 ring-blue-500' :  // 요청
          state.includes('request_proto') ? 'bg-orange-100 text-orange-600 ring-1 ring-orange-500' :  // 출력
              state.includes('done') ? 'bg-green-100 text-green-600 ring-1 ring-green-500' :  // 완료
                  state.includes('hold') ? 'bg-gray-300 text-gray-700 ring-1 ring-gray-500' : 'bg-white text-gray-900 ring-1 ring-gray-500' // 보류, 그외
}

const TicketListTable = ({isShort, gameDataList, onSelectedTickets, ticketOrderType, ticketState, cardHandler}) => {
  const [ticketModalData, setTicketModalData] = useState({isOpen: false});
  const [isSelectedTickets, setIsSelectedTickets] = useState([]);
  const [selectedSheetQuantity, setSelectedSheetQuantity] = useState(SHEET_QUANTITY[0]);

  const cardHandlerInstance = new CardHandlerInstance(() => {
    let emptyList = [];
    if (onSelectedTickets !== null && onSelectedTickets !== undefined)
      onSelectedTickets(emptyList);
    setIsSelectedTickets(emptyList);
  });

  if (cardHandler) {
    cardHandler(cardHandlerInstance);
  }

  useEffect(() => {
    console.log('TicketListTable ->', gameDataList);
  }, [ticketModalData]);

  const selectAllTicket = (isChecked) => {
    // 티켓 전체선택
    let tempList = [];
    if (isChecked) {
      for (let f of gameDataList) {
        if (f.state.includes('expired')) continue;
        tempList.push(f.id);
      }
      if (onSelectedTickets !== null && onSelectedTickets !== undefined)
        onSelectedTickets(tempList);
      setIsSelectedTickets([...tempList]);
      return;
    }
    if (onSelectedTickets !== null && onSelectedTickets !== undefined)
      onSelectedTickets([]);
    setIsSelectedTickets([]);
  }

  const isAllChecked = () => {
    // 전체 체크 여부
    let count = 0;
    for (let f of gameDataList) {
      if (f.state.includes('expired')) continue;
      count++;
    }
    if (count === 0)
      return false;
    return count === isSelectedTickets.length;
  }

  const handleSelectTicketChange = (key, state) => {
    // 티켓 만료여부 확인
    if (state.includes('expired')) {
      toast.warn('만료된 티켓입니다.')
      return;
    }
    if (ticketOrderType.options.includes("single-selection")) {
      // 하나만 선택하려고 할 때 (여러개 선택 X)
      let _newTickets;
      setIsSelectedTickets((prevTickets) => {
        if (prevTickets.includes(key)) {
          _newTickets = [];
        } else {
          _newTickets = [key];
        }

        if (onSelectedTickets !== null && onSelectedTickets !== undefined)
          onSelectedTickets(_newTickets);

        return _newTickets;
      });
      return;
    }
    // 티켓 하나씩 체크할 때 (여러개 O)
    setIsSelectedTickets((prevTickets) => {
      let _newTickets;
      if (prevTickets.includes(key)) {
        _newTickets = prevTickets.filter((selectedId) => selectedId !== key);
      } else {
        _newTickets = [...prevTickets, key];
      }
      if (onSelectedTickets !== null && onSelectedTickets !== undefined)
        onSelectedTickets(_newTickets);

      return _newTickets;
    });
  }
  let _isAllChecked = isAllChecked();

  return (
      <div className="flex flex-col mt-3 lg:ml-5">
        {ticketModalData.isOpen &&
            <TicketCardModal ticketModalData={ticketModalData} setTicketModalData={setTicketModalData}
                             ticketOrderType={ticketOrderType} ticketState={ticketState}/>}

        <div className="ml-4 lg:ml-10 lg:mt-0 flex h-6 items-center text-center font-semibold">
          <input
              onClick={(e) => selectAllTicket(e.target.checked)}
              type="checkbox"
              checked={_isAllChecked}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
          />
          <span>전체 선택</span>
        </div>

        <table className="mt-5  min-w-full divide-y divide-gray-300">
          <thead>
          <tr>
            {(isShort ? TICKET_TABLE_HEADER_SHORT : TICKET_TABLE_HEADER_DEFAULT).map((data) => {
              return (
                  <th scope="col"
                      key={data.name}
                      className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-white lg:table-cell bg-gray-900 border-t">
                    <p>{data.name}</p>
                  </th>
              );
            })}
          </tr>
          </thead>

          <tbody className="divide-y divide-gray-300 bg-white">
          {gameDataList.map((data) => {
            let amount = data.amount || 0;
            if (data.newAmount) amount = data.newAmount;
            if (data.selectGame.length === 0)
              return (<></>);
            return (
                <tr key={data.number}
                    className={neverLandUtils.classNames(isSelectedTickets.includes(data.id) ? "hover:bg-amber-100 bg-amber-100" : "hover:bg-blue-50", "whitespace-nowrap px-3 text-center text-sm font-medium text-gray-900 items-center")}>

                  {/* 체크박스, 의뢰번호 */}
                  <td className="whitespace-nowrap items-center justify-center py-3 flex flex-row">
                    <input
                        onClick={() => handleSelectTicketChange(data.id, data.state)}
                        checked={isSelectedTickets.includes(data.id)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 mr-2"
                    />
                    {!isShort && <p>{data.requestNumber}</p>}
                  </td>

                  {/* 고객닉네임 */}
                  <td> {data.registerName.split('|')[0].trim()} </td>

                  {/* 의뢰상태 */}
                  <td>
                    <span
                        className={`inline-flex items-center rounded-full px-3 py-1 text-xs font-semibold ring-1  ${getBadgeStateStyleText(data.state)}`}>
                       {getCardStateDisplayText(data.state)}
                     </span>
                  </td>

                  {/* 의뢰시각 */}
                  <td> {data.registDate.substring(5, 16)} </td>

                  {!isShort &&
                      <td>
                        {/* 방문예정 */}
                        {data.reservDate.substring(5, 19)}
                      </td>
                  }

                  {!isShort &&
                      <td>
                        {/* 처리시각 */}
                        {data.progressDate.substring(5, 16)}
                      </td>
                  }

                  {/* 최종경기시작 */}
                  <td> {data.closingDate.substring(5, 16)} </td>

                  {/* 예상적중배당 */}
                  <td> {data.totalRate} </td>

                  {/* 총투표액 */}
                  <td> {amount.toLocaleString()} </td>

                  {/* 예상적중금 */}
                  <td> {Math.floor((amount) * (data.totalRate || 0)).toLocaleString() || 0} </td>

                  {/* 메모 */}
                  {!isShort && <td><p> {data.registerMemo} </p></td>}

                  {/* 복사 */}
                  {/*{!isShort && <td>*/}
                  {/*  <ListSelectBox listData={SHEET_QUANTITY}*/}
                  {/*                 onChange={(e) => {*/}
                  {/*                   data.copyNumber = e.name.replace("매", "").trim();*/}
                  {/*                   setSelectedSheetQuantity(e)*/}
                  {/*                 }}/>*/}
                  {/*</td>}*/}

                  {/* 게임폴더 */}
                  <td>
                    <button
                        onClick={() => setTicketModalData({isOpen: true, data: data, isTest: false})}
                        className="rounded-2xl px-4 py-1 bg-sky-600 text-white hover:bg-sky-500">
                      {data.selectGame.length}
                    </button>
                  </td>
                </tr>
            )
          })}
          </tbody>
        </table>
      </div>
  )
}
export default TicketListTable;