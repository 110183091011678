import Sidebar from "../components/Sidebar";
import MyPageHeader from "../components/Header/MyPageHeader";
import React, {useEffect, useState} from "react";
import fetcher, {getFetcher} from "../utils/fetcher";
import {CHANGE_PASSWORD_API, EDIT_MY_INFO_API, GET_MY_INFO_API} from "../constants/api_constants";
import neverLandUtils from "../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {ModalHandler, OpenModalHandle} from "../components/Modal/Generic/ModalHandler";
import {accountInfo} from "../constants/constants";
import {MAIN_PAGE, MY_INFO_PAGE, MY_STORE_LIST_PAGE} from "../constants/page_constants";
import {Link, useNavigate} from "react-router-dom";
import {RiEyeFill, RiEyeOffFill} from "react-icons/ri";
import {updateMyInfo} from "../utils/NeverLandRestFULInterface";
import neverLandGameData from "../utils/NeverLandGameData";

const MyInfoPage = () => {
  // 내 정보
  const [myName, setMyName] = useState('');
  const [myNickName, setMyNickName] = useState('');
  const [myEmail, setMyEmail] = useState('');
  const [myPhoneNumber, setMyPhoneNumber] = useState('');
  const [myAccountInfo, setMyAccountInfo] = useState({});
  // 비밀번호 관련
  const [prePassword, setPrePassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState({
    prePassword: false,
    newPassword: false,
    newPasswordConfirm: false
  });

  const [showChangeProfileImg, setShowChangeProfileImg] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const profileGroups = [
    // 필요한 만큼 그룹 추가
    {
      groupName: "프로필 그룹 1",
      range: [1, 5] // profile01~profile05
    },
    {
      groupName: "프로필 그룹 2",
      range: [6, 10] // profile06~profile13
    },
    {
      groupName: "프로필 그룹 3",
      range: [11, 15] // profile14~profile20
    },
    {
      groupName: "프로필 그룹 4",
      range: [16, 20]
    },
    {
      groupName: "프로필 그룹 5",
      range: [21, 25]
    },
    {
      groupName: "프로필 그룹 6",
      range: [26, 30]
    },
    {
      groupName: "프로필 그룹 7",
      range: [31, 35]
    },
    {
      groupName: "프로필 그룹 8",
      range: [36, 40]
    },
  ];
  const profiles = Array.from({length: 40}, (_, i) => `profile${String(i + 1).padStart(2, '0')}.jpg`);

  const OpenModal = OpenModalHandle.create();

  useEffect(() => {
    getUserInfo();
  }, [])


  const getUserInfo = (doReLogin) => {
    // <-- 내 정보 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(GET_MY_INFO_API))
        .then(res => {
          // 계정 차단 여부 확인
          if (neverLandUtils.security.checkLogout(res)) {
            return;
          }

          if (res.data.header.type === "success") {
            const {userName, nickName, email, phoneNumber} = res.data.result.myAccountInfo;
            setMyName(userName);
            setMyNickName(nickName);
            setMyEmail(email);
            setMyPhoneNumber(phoneNumber);
            setMyAccountInfo(res.data.result.myAccountInfo);
            if (doReLogin) {
              setTimeout(() => {
                updateMyInfo(null);
              }, 250)
            }
          } else {
            toast.error(res.data.header.message);
          }
        }).catch(err => toast.error(err.message));
  }

  const editMyInfo = () => {
    // <-- 내 정보 변경 -->
    getFetcher().post(EDIT_MY_INFO_API, {
      user: accountInfo.makeJson(),
      nickName: myNickName,
      phoneNumber: myPhoneNumber,
    }).then(res => {
      const header = res.data.header;
      if (header.type === "success") {
        toast.success("변경이 완료 되었습니다.");
        getUserInfo(true);
      } else {
        toast.error("변경이 실패하였습니다.");
      }
    })
  }

  const onChangePassword = (newEnterPassword, newEnterPasswordConfirm) => {
    // <-- 비밀번호 검증 -->
    if (newEnterPassword !== newPassword) {
      setNewPassword(newEnterPassword);
    }
    if (newEnterPasswordConfirm !== newPasswordConfirm) {
      setNewPasswordConfirm(newEnterPasswordConfirm);
    }
    if (newEnterPassword === newEnterPasswordConfirm) {
      setIsPasswordConfirm(true);
    } else {
      setIsPasswordConfirm(false);
    }
  }

  // <-- 비밀번호 변경 -->
  const changePassword = () => {
    const encryptPrePassword = neverLandUtils.security.encrypt(prePassword);
    const encryptNewPassword = neverLandUtils.security.encrypt(newPassword);
    setNewPasswordConfirm(newPasswordConfirm);
    if (newPassword !== newPasswordConfirm) {
      OpenModal.openWarningModal('비밀번호 불일치', '입력하신 비밀번호가 일치하지 않습니다.');
    } else {
      getFetcher().post(CHANGE_PASSWORD_API, {
        user: accountInfo.makeJson(),
        prePassword: encryptPrePassword,
        newPassword: encryptNewPassword
      })
          .then(res => {
            const header = res.data.header;
            if (header.type === 'fail') {
              OpenModal.openWarningModal('변경 실패', header.message);
            } else if (header.type === 'success') {
              toast.success('비밀번호 변경이 완료되었습니다.');
              setPrePassword('');
              setNewPassword('');
              setNewPasswordConfirm('');
            }
          })
          .catch(err => console.log(err))
      // 백엔드에서 현재 비밀번호 일치하는지 확인해줘야됨
    }
  }

  // 비밀번호 보이기
  const toggleVisiblePassword = (fieldName) => {
    setShowPasswordFields(prevState => ({
      ...prevState, [fieldName]: !prevState[fieldName]
    }));
  }

  // <-- 회원 탈퇴 -->
  const deleteAccount = () => {
  }

  function getMyDefaultStore(callback) {
    // <-- 기본지점 가져오기 -->
    if (!myAccountInfo)
      return;
    if (!myAccountInfo.myStoreList)
      return;
    for (let store of myAccountInfo.myStoreList) {
      if (store.defaultStore) {
        return callback(store);
      }
    }
    return callback(null);
  }

  return (
      <>
        <div>
          <ModalHandler path={MY_INFO_PAGE} initModalHandle={OpenModal}/>

          <Sidebar/>
          <div className="lg:pl-64">
            <MyPageHeader/>
            <main>
              <div className="lg:mt-20 divide-y divide-gray-300">
                <div
                    className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-6 sm:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div className="sm:ml-10">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900">개인정보</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      개인정보 조회
                    </p>
                  </div>

                  <div className="md:col-span-2">
                    <div className="grid grid-cols-2 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full flex flex-row items-center gap-x-8 whitespace-nowrap">
                        <div className="relative flex flex-col items-center">
                          <img
                              src={accountInfo.getProfileImage()}
                              style={{backgroundColor: 'white'}}
                              alt="profileImage"
                              className="h-24 w-24 flex-none rounded-full bg-gray-800 object-cover"
                          />
                          {/*<button*/}
                          {/*    className="text-xs bg-gray-100 px-2 py-0.5 rounded-lg mt-2 font-semibold border border-gray-400"*/}
                          {/*    onClick={() => setShowChangeProfileImg(true)}>프로필 설정*/}
                          {/*</button>*/}

                          {showChangeProfileImg &&
                              <div
                                  className="w-[38rem] absolute z-20 py-5 px-3 rounded-3xl bg-black bg-opacity-90 mt-12">
                                <div
                                    className="flex flex-col items-center justify-between max-h-[30rem] overflow-y-auto ">
                                  <div className="flex flex-col justify-center">
                                    {profileGroups.map((group, index) => (
                                        <div key={index} className="my-3">
                                          <p className="font-bold mb-3 ml-2 text-white">{group.groupName}</p>
                                          <div className="flex flex-row flex-wrap justify-center">
                                            {profiles.slice(group.range[0] - 1, group.range[1]).map((profile, idx) => (
                                                <img
                                                    key={idx}
                                                    src={`http://teamneverland.com/resources/img/user-profiles/${profile}`}
                                                    className={neverLandUtils.classNames(
                                                        selectedProfile === idx + group.range[0] - 1 ? "" : "",
                                                        "cursor-pointer h-24 w-24 rounded-full mx-2"
                                                    )}
                                                    style={
                                                      selectedProfile === idx + group.range[0] - 1
                                                          ? {boxShadow: "0 0 20px rgba(185, 180, 150, 0.7), 0 0 15px rgba(180, 120, 150, 0.65), 0 0 10px rgba(140, 50, 80, 0.65), 0 0 40px rgba(140, 50, 80, 0.65)"}
                                                          : {}
                                                    }
                                                    alt={`Profile ${idx + group.range[0]}`}
                                                    onClick={() => setSelectedProfile(idx + group.range[0] - 1)}
                                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                />
                                            ))}
                                          </div>
                                        </div>
                                    ))}
                                  </div>
                                </div>

                                <div className="flex flex-row justify-center">
                                  {selectedProfile !== null && (
                                      <button
                                          className="text-sm bg-gray-100 px-2 py-0.5 rounded-lg mt-4 font-semibold"
                                          onClick={() => setShowChangeProfileImg(true)}>
                                        프로필 변경
                                      </button>
                                  )}

                                  <button
                                      className="text-sm bg-gray-400 px-2 py-0.5 rounded-lg mt-4 font-semibold ml-3"
                                      onClick={() => {
                                        setShowChangeProfileImg(false);
                                        setSelectedProfile(null);
                                      }}>
                                    닫기
                                  </button>
                                </div>
                              </div>
                          }

                        </div>
                        <div>
                          <p className="text-xl font-semibold leading-7 text-gray-900"> {myName || ''} </p>
                          {/*<p className="mt-2 text-xs leading-5 text-gray-400">앱푸시 알람설정</p>*/}
                        </div>
                      </div>

                      <div className="col-span-3">
                        <label htmlFor="nickname" className="block text-sm font-semibold leading-6 text-gray-900">
                          닉네임
                        </label>
                        <div className="mt-2">
                          <input
                              value={myNickName || ''}
                              onChange={(e) => setMyNickName(e.target.value)}
                              id="nickname"
                              spellCheck="false"
                              className=
                                  {neverLandUtils.classNames(myAccountInfo.nickName === myNickName ? "text-gray-900" : "font-semibold text-orange-500",
                                      "block w-full rounded-md border-0 py-1.5 pl-3  ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                                  )}
                          />
                        </div>
                      </div>

                      <div className="col-span-4">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                          메일주소
                        </label>
                        <div className="mt-2">
                          <input
                              value={myEmail || ''}
                              id="email"
                              disabled={true}
                              autoComplete="false"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-4">
                        <label htmlFor="contact-info" className="block text-sm font-semibold leading-6 text-gray-900">
                          연락처
                        </label>
                        <div className="mt-2">
                          <input
                              value={myPhoneNumber || ''}
                              onChange={(e) => setMyPhoneNumber(e.target.value)}
                              id="contact-info"
                              spellCheck="false"
                              autoComplete="new-password" // 아이디 비밀번호 크롬에 저장된거 자동으로 불러와져서 설정해놓음
                              className={neverLandUtils.classNames(myAccountInfo.phoneNumber === myPhoneNumber ? "text-gray-900" : "font-semibold text-orange-500",
                                  "block w-full rounded-md border-0 py-1.5 pl-3  ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                              )}
                          />
                        </div>
                      </div>

                      <div className="col-span-4">
                        <label htmlFor="store" className="block text-sm font-semibold leading-6 text-gray-900">
                          기본 지점
                        </label>
                        <div className="mt-2">
                          {getMyDefaultStore((store) => {
                            return store ?
                                (<div className="text-sm flex">{store.status === "confirmed" ?
                                    <p>{store.name} | {store.address} | {store.tel}</p>
                                    : <>
                                      {store.state === 'regist' && (
                                          <span
                                              className="mr-3 inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700"> 승인대기중 </span>
                                      )}
                                      {store.state === 'deferring' && (
                                          <span
                                              className="mr-3 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800"> 보류 </span>
                                      )}
                                      {store.state === 'banned' && (
                                          <span
                                              className="mr-3 inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"> 거부 </span>
                                      )}
                                      <p>{store.name} | {store.tel}</p>
                                    </>}
                                </div>)
                                :
                                (<div className="text-sm">
                                  <p className="text-red-500">기본으로 설정된 지점이 없습니다.</p>
                                  <div className="flex flex-row mt-2"><p>(※</p>
                                    <Link to={MY_STORE_LIST_PAGE}><p className="text-indigo-500 font-semibold">내지점관리</p>
                                    </Link><p>에서 등록해주세요)</p>
                                  </div>
                                </div>)
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="mt-8 flex">
                      <button
                          onClick={editMyInfo}
                          disabled={myAccountInfo.phoneNumber === myPhoneNumber && myAccountInfo.nickName === myNickName}
                          className={neverLandUtils.classNames((myAccountInfo.phoneNumber === myPhoneNumber && myAccountInfo.nickName === myNickName) ?
                              "bg-gray-300" : "bg-blue-900 pcSizeHover:bg-blue-800", "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
                          )}
                      >
                        개인 정보 수정
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div className="sm:ml-10">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900">비밀번호 변경</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      계정과 연결된 암호를 업데이트합니다.
                    </p>
                  </div>

                  <div className="md:col-span-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-4">
                        <label htmlFor="pre-password"
                               className="block text-sm font-semibold leading-6 text-gray-900">
                          현재 비밀번호
                        </label>
                        <div className="mt-2 relative flex flex-row items-center">
                          <input
                              onChange={(e) => setPrePassword(e.target.value)}
                              value={prePassword || ''}
                              type={showPasswordFields.prePassword ? "text" : "password"}
                              placeholder="현재 비밀번호"
                              id="pre-password"
                              autoComplete="new-password"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                          />
                          <div className="absolute px-3 text-gray-400 right-0">
                            {showPasswordFields.prePassword ?
                                <RiEyeFill className="h-5 w-auto"
                                           onClick={() => toggleVisiblePassword('prePassword')}/> :
                                <RiEyeOffFill className="h-5 w-auto"
                                              onClick={() => toggleVisiblePassword('prePassword')}/>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="col-span-4">
                        <label htmlFor="new-password" className="block text-sm font-semibold leading-6 text-gray-900">
                          새 비밀번호
                        </label>
                        <div className="mt-2 relative flex flex-row items-center">
                          <input
                              onChange={e => onChangePassword(e.target.value, newPasswordConfirm)}
                              value={newPassword || ''}
                              type={showPasswordFields.newPassword ? "text" : "password"}
                              placeholder="새 비밀번호"
                              autoComplete="new-password"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                          />
                          <div className="absolute px-3 text-gray-400 right-0">
                            {showPasswordFields.newPassword ?
                                <RiEyeFill className="h-5 w-auto"
                                           onClick={() => toggleVisiblePassword('newPassword')}/> :
                                <RiEyeOffFill className="h-5 w-auto"
                                              onClick={() => toggleVisiblePassword('newPassword')}/>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="col-span-4">
                        <label htmlFor="confirm-password"
                               className="block text-sm font-semibold leading-6 text-gray-900">
                          새 비밀번호 확인
                        </label>
                        <div className="mt-2 relative flex flex-row items-center">
                          <input
                              onChange={e => onChangePassword(newPassword, e.target.value)}
                              value={newPasswordConfirm || ''}
                              type={showPasswordFields.newPasswordConfirm ? "text" : "password"}
                              placeholder="새 비밀번호 확인"
                              autoComplete="new-password"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                          />
                          <div className="absolute px-3 text-gray-400 right-0">
                            {showPasswordFields.newPasswordConfirm ?
                                <RiEyeFill className="h-5 w-auto"
                                           onClick={() => toggleVisiblePassword('newPasswordConfirm')}/> :
                                <RiEyeOffFill className="h-5 w-auto"
                                              onClick={() => toggleVisiblePassword('newPasswordConfirm')}/>
                            }
                          </div>
                        </div>

                        {newPassword && newPasswordConfirm && (
                            <p className={`mt-2 text-sm ${isPasswordConfirm ? "text-green-600" : "text-red-600"}`}>
                              {isPasswordConfirm ? "비밀번호가 일치합니다." : "비밀번호가 일치하지 않습니다."}
                            </p>
                        )}
                      </div>
                    </div>

                    <div className="mt-8 flex">
                      <button
                          onClick={changePassword}
                          disabled={!newPassword || !newPasswordConfirm || !isPasswordConfirm}
                          className={neverLandUtils.classNames(prePassword && newPassword && newPasswordConfirm && isPasswordConfirm ?
                              "bg-blue-900 hover:bg-blue-800" : "bg-gray-300", "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
                          )}
                      >
                        변경하기
                      </button>
                    </div>
                  </div>
                </div>

                {/*<div className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">*/}
                {/*  <div className="sm:ml-10">*/}
                {/*    <h2 className="text-xl font-semibold leading-7 text-gray-900">회원탈퇴</h2>*/}
                {/*    <p className="mt-1 text-sm leading-6 text-gray-400">*/}
                {/*      더 이상 저희 서비스를 이용하고 싶지 않으신가요? <br/> 탈퇴하시면 계정과 관련된 모든 정보는 삭제됩니다.*/}
                {/*    </p>*/}
                {/*  </div>*/}

                {/*  <div className="flex items-start md:col-span-2">*/}
                {/*    <button*/}
                {/*        onClick={deleteAccount}*/}
                {/*        type="button"*/}
                {/*        className="sm:mt-10 rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm"*/}
                {/*    >*/}
                {/*      회원탈퇴*/}
                {/*    </button>*/}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
            </main>
          </div>
        </div>
      </>
  )
}
export default MyInfoPage;