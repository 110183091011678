//라이브 스코어 어플 알림 작업 -- 진짜용 live score 코드

import {accountInfo} from "../constants/constants";
import {toast} from "react-toastify";
import neverLandNotificationInterface from "../utils/NeverLandNotificationInterface";


let _storePrintHandler = null;
let _refreshStorePrintHandler = null;



const WindowInterfaces = () => {

  function partner_get_page_version() {
    // 버전이 바뀌면 캐시폴더를 다날리고 새로고침 (PC용)
    return "0.0.0.2t";
  }

  function partner_config_useprinting(use_printing) {
    if (use_printing)
      accountInfo.activateUsePrinting();
    // toast.warn('partner_config_useprinting->'+use_printing);
  }

  function partner_printing_status(printing_status_params) {
    if (!printing_status_params.includes('done')) {
      return;
    }
    if (_storePrintHandler) {
      _storePrintHandler(printing_status_params, _refreshStorePrintHandler);
    }
  }

  function partner_get_printer_setting() {
  }

  function mobile_set_config(type, key, value, message) {
    _isMobile = true;
    if (type === 'message') {
      toast.success(message);
      return;
    }
    if (type === 'start.app') {
      return;
    }
    if (type === 'alert') {
      toast.success(message);
      return;
    }
    if (type === 'push.notifications') {
      mobileInterface.debug("push.notification["+value+"]");
      neverLandNotificationInterface.pushNotificationsByScript(value);
      return;
    }
    if (type === 'suspend.notification') {
      mobileInterface.debug("suspend.notification");
      neverLandNotificationInterface.isSuspend=true;
      return;
    }
    if (type === 'resume.notification') {
      mobileInterface.debug("resume.notification");
      neverLandNotificationInterface.isSuspend=false;
      return;
    }
    if (type === 'start.intentAction') {
      if (value.includes('navi')) {
        window.location.href = getPageUrlMapping(value);
        //toast.success(value);
      }
    }
  }


  function getPageUrlMapping(value) {
    if (value.includes("store/customermanagement")) {
      return "store_customer_management";
    }
    if (value.includes("store/storerequestlist")) {
      return "store_request_list";
    }
    if (value.includes("user/mystore")) {
      return "my_store";
    }
    if (value.includes("user/requestlist")) {
      return "request_list";
    }
    return ".";
  }

  window.partner_get_page_version = partner_get_page_version;
  window.partner_config_useprinting = partner_config_useprinting;
  window.partner_printing_status = partner_printing_status;
  window.partner_get_printer_setting = partner_get_printer_setting;
  window.mobile_set_config = mobile_set_config;


  return (<></>)
}

let _isMobile = false;

class MobileInterface {

  isMobile() {
    // 앱으로 들어가야 확인됨 PC 에서는 확인 불가
    return _isMobile;
  }

  mobile_notification(intentAction, type, message) {
    window.mobile.notification(type, intentAction, message);
  }
  mobile_actionCommand(command, parameters) {
    window.mobile.actionCommand(command, parameters);
  }


  doTest(){
    let value = 'push.notifications=user/mystore|1732024957|normal|신규 승인이 완료되었습니다. (큐티섹시 / 자동 등록)|navigate:user/mystore';

  //  value=null;
    if(value)
      neverLandNotificationInterface.pushNotificationsByScript(value);
    else {
      console.log('doTest=null');
    }
  }
  debug(message) {
    // if(message.includes('push')){
    //     toast.success(message);
    // }
  }
}

export const mobileInterface = new MobileInterface();

export function setStorePrintHandler(handler) {
  _storePrintHandler = handler;
}

export function setStorePrintDoneHandler(handler) {
  _refreshStorePrintHandler = handler;
}

export default WindowInterfaces;