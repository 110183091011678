import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {RiCloseLine} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";

const SimpleTextEditModal = ({title, modalData, setModalData}) => {
  const [open, setOpen] = useState(true);
  const [inputText, setInputText] = useState(modalData.text);
  const [memoList, setMemoList] = useState([]);
  const [oftenMemoList, setOftenMemoList] = useState([]);

  const closeModal = () => {
    setOpen(false);
    setModalData({isOpen: false});
  }

  useEffect(() => {
    const loadList = neverLandUtils.commons.load('ticket_memo_history');
    setMemoList(loadList || []);

    const sortList = loadList?.sort((a, b) => {
      return b.count - a.count;
    });
    setOftenMemoList(sortList?.slice(0, 5) || []);
  }, []);

  const onEditMemoButton = () => {
    setModalData({...modalData, isOpen: false, text: inputText});

    const isOverlapIndex = memoList.findIndex(memo => memo.memo === inputText);

    if (isOverlapIndex !== -1) {
      // 중복되는 값이 있을 때 - count 증가
      const newList = [...memoList];
      newList[isOverlapIndex].count++;
      setMemoList(newList);
      neverLandUtils.commons.save('ticket_memo_history', newList);
    } else {
      // 중복되는 값이 없을 때 - 새 메모 추가
      const newList = [{memo: inputText, count: 0}, ...memoList]
      setMemoList(newList);
      neverLandUtils.commons.save('ticket_memo_history', newList);
    }
  }

  const removeMemo = (deleteMemo) => {
    // 선택한 메모 삭제
    const filterList = memoList.filter(memo => memo.memo !== deleteMemo);
    setMemoList(filterList);
    neverLandUtils.commons.save('ticket_memo_history', filterList);
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-60" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">

                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-xl font-semibold leading-6 text-gray-900">{title}</h3>
                  </div>

                  <div className="flex flex-col justify-center mt-5 sm:mt-6">
                    <div className="flex flex-row">
                      <input
                          onChange={(e) => setInputText(e.target.value)}
                          onFocus={(e) => e.target.select()}
                          spellCheck="false"
                          value={inputText}
                          className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />

                      <div className="flex flex-row items-center">
                        <button
                            onClick={onEditMemoButton}
                            className="ml-2 inline-flex justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 whitespace-nowrap"
                        >
                          수정
                        </button>
                        <button
                            onClick={closeModal}
                            type="submit"
                            className="ml-2 inline-flex rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-100 whitespace-nowrap"
                        >
                          취소
                        </button>
                      </div>
                    </div>


                    {memoList.length>0&&
                      <div className="flex flex-row justify-between mt-5">
                        <div className="flex flex-col">
                          <p className="text-md font-semibold whitespace-nowrap">자주 작성한 메모</p>
                          {oftenMemoList?.map((memo) => {
                            return (
                                <p onClick={() => setInputText(memo.memo)}
                                   className="mt-2 text-sm hover:bg-yello-50 cursor-pointer">
                                  {memo.memo}
                                </p>
                            )
                          })}
                        </div>

                        <div className="w-1/2 whitespace-nowrap">
                          <p className="text-md font-semibold">최근 작성한 메모</p>
                          <div className="max-h-48 overflow-y-auto">
                            {memoList?.map((memo) => {
                              return (
                                  <div className="flex flex-row items-center text-sm mt-2 justify-between">
                                    <p onClick={() => setInputText(memo.memo)}
                                       className="cursor-pointer">
                                      {memo.memo}
                                    </p>
                                    <RiCloseLine onClick={() => removeMemo(memo.memo)}
                                                 className="h-5 w-auto text-red-500 ml-2 cursor-pointer"/>
                                  </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default SimpleTextEditModal;
