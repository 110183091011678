import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useState} from "react";
import {RiCloseLine} from "react-icons/ri";
import BaseTicketCard from "../Card/BaseTicketCard";


const TicketCardModal = ({ticketModalData, setTicketModalData, ticketOrderType, ticketState, onRefreshTicket}) => {
  const [open, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
    setTicketModalData({isOpen: false});
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="-mx-2">
                    <div className="flex flex-row justify-between">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">티켓정보</h3>
                      <button onClick={closeModal}>
                        <RiCloseLine className="w-5 h-5"/>
                      </button>
                    </div>

                    {/*TODO : 티켓에서 메모 수정하고 닫아도 리스트에서 바뀌지않음 / 상단 컴포넌트에서 reload 걸어줘야됨*/}
                    <BaseTicketCard gameDataList={[ticketModalData.data]}
                                    ticketOrderType={ticketOrderType}
                                    ticketState={ticketState}
                                    onRefreshTicket={onRefreshTicket}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default TicketCardModal;