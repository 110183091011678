//라이브 스코어 어플 알림 작업 -- 진짜용 live score 코드
//테스트 버튼을 누르면 알림이 갈 수 있는 수동 TEST 기능을 만들었음

import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import {accountInfo} from "../../constants/constants";
import {Environment} from "../../utils/_dummyData";
import {EXCEL_LOAD_PAGE} from "../../constants/page_constants";
import {RiFeedbackFill} from "react-icons/ri";
import AdminNoticeModal from "../../components/Modal/AdminNoticeModal";
import neverLandUtils from "../../utils/NeverLandUtils";
import WindowInterfaces, {mobileInterface} from "../WindowInterfaces";

const DevelopmentHistoryPage = () => {
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);

  const checkShowNoticeModal = () => {
    // 확인 눌러서 로컬에 isOpen:false 일때 모달 안보이게
    if (neverLandUtils.commons.load('admin_noti_modal').isOpen === false) {
      setNoticeModalOpen(false);
    }
    return false;
  }

  useEffect(() => {
    // 로컬 스토리지에서 알림창 값 불러오기
    const getNotiToLocal = neverLandUtils.commons.load('admin_noti_modal');

    if (getNotiToLocal === null) {
      neverLandUtils.commons.save('admin_noti_modal', {isOpen: true});
      setNoticeModalOpen(true);
    } else {
      // 확인 눌러서 로컬에 isOpen:false 일때 모달 안보이게
      if (getNotiToLocal.isOpen === false) {
        setNoticeModalOpen(false);
      } else {
        checkShowNoticeModal();
      }
    }
  }, []);

  return (
      <div>
        {noticeModalOpen && <AdminNoticeModal setNoticeModalOpen={setNoticeModalOpen}/> }

        <Sidebar/>

        <main className="lg:pl-64 min-h-screen">
          <AdminPageHeader/>

          {Environment.isTest && accountInfo.isAdmin() &&
              <div className="min-h-screen pt-32 pl-14 flex flex-col">
                <div className="w-48">
                  <a href={EXCEL_LOAD_PAGE} target="_blank"
                     className="rounded-md p-2 h-10 text-sm leading-6 font-semibold bg-green-700 text-white whitespace-nowrap text-center">
                    테스트 이력(엑셀)
                  </a>
                </div>

                <div className="border-t border-gray-300 my-10 lg:mr-60 mr-10"></div>

                <div className="flex flex-col justify-start">

                  <button
                      className="cursor-pointer flex flex-row py-0.5 bg-rose-600 text-white font-semibold items-center border-2 border-rose-600 w-48 h-10 rounded-lg justify-center"
                      onClick={() => setNoticeModalOpen(true)}
                  >
                    업데이트 내용 보기
                    <RiFeedbackFill className="h-6 w-auto ml-1"/>
                  </button>

                  <a href="https://dev.teamneverland.com/resources/admin/mobydick-admin.apk" target="_blank"
                     className="w-48 gap-x-3 rounded-md p-2 h-10 text-sm leading-6 font-semibold bg-blue-900 text-white whitespace-nowrap text-center my-10">
                    관리자 앱 다운로드
                  </a>

                  <a href="https://dev.teamneverland.com/resources/admin/MobydicSetup.exe" target="_blank"
                     className="w-48 gap-x-3 rounded-md p-2 h-10 text-sm leading-6 font-semibold bg-blue-600 text-white whitespace-nowrap text-center">
                    관리자 PC 다운로드
                  </a>

                  <div
                     className="w-48 gap-x-3 rounded-md p-2 h-10 text-sm leading-6 font-semibold bg-yellow-300 text-orange-900 whitespace-nowrap text-center my-10"
                      onClick={()=>{mobileInterface.doTest();}}>
                    테스트
                  </div>
                </div>
              </div>
          }
        </main>
      </div>
  )
}
export default DevelopmentHistoryPage;