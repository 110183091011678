import Sidebar from "../../components/Sidebar";
import React, {useEffect, useRef, useState} from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/locale';
import DatePickerInput from "../../components/DatePickerInput";
import Pagination from "../../components/Pagination/Pagination";
import ListSelectBox from "../../components/ListSelectBox";
import {getFetcher} from "../../utils/fetcher";
import {ALL_CUSTOMER_LIST_API, ALL_CUSTOMER_LIST_API_EX} from "../../constants/api_constants";
import {accountInfo, lastMonth, lastWeek, today, yesterday} from "../../constants/constants";
import {toast} from "react-toastify";
import AllCustomerManagementModal from "../../components/Modal/AllCustomerManagementModal";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import neverLandUtils from "../../utils/NeverLandUtils";
import {RiArrowDownSFill, RiArrowUpSFill, RiExpandUpDownFill} from "react-icons/ri";

const filterOption = [
  {name: "전체", code: "all"},
  {name: "닉네임", code: "nick_name"},
  {name: "성명", code: "name"},
  {name: "이메일", code: "email"},
  {name: "연락처", code: "phoneNumber"},
]


const AllCustomerManagementPage = () => {
  const [customerList, setCustomerList] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [customerInfo, setCustomerInfo] = useState([]);
  // 검색조건
  const [startDate, setStartDate] = useState(new Date(Date.parse("2020-01-01")));
  const [endDate, setEndDate] = useState(new Date());
  const [searchCondition, setSearchCondition] = useState(filterOption[0]);
  const [searchConditionValue, setSearchConditionValue] = useState('');
  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  let pageSize = 15;
  const [refreshKey, setRefreshKey] = useState(new Date());

  const makeTableHeader = (name) => {
    return (
        <div className="flex justify-center">
          <div key={name} className="whitespace-nowrap px-3 text-center text-sm font-semibold text-white lg:table-cell">
            <div className="flex items-center justify-center cursor-pointer"
                 onClick={() => makeAlignData(name)}>
              <span>{name}</span>
              {currentAlignData[name] !== undefined && currentAlignData[name] !== '-' ?
                  (currentAlignData[name] === "desc" ?
                          <RiArrowDownSFill className="h-5 w-5 text-yellow-500 ml-1"/>
                          : <RiArrowUpSFill className="h-5 w-5 text-sky-500 ml-1"/>
                  ) : <RiExpandUpDownFill className="h-4 w-4 text-gray-400 ml-1"/>
              }
            </div>
          </div>
        </div>);
  }

  const [currentAlignData, setCurrentAlignData] = useState(accountInfo.getAlignDataList("CustomerManagementPage"));

  function makeAlignData(name) {
    // <-- 테이블 오름차순/내림차순 정렬 -->
    let prevData = currentAlignData[name];
    if (prevData === undefined || prevData === "-") {
      currentAlignData[name] = "asc";
    } else if (prevData === "asc") {
      currentAlignData[name] = "desc";
    } else if (prevData === "desc") {
      currentAlignData[name] = "-";
    }
    let newAlignData = {...currentAlignData};

    accountInfo.saveAlignData("CustomerManagementPage", newAlignData);

    // if (setAlignData)
    //   setAlignData(jsonDataToString(newAlignData));
    setCurrentAlignData(newAlignData);
  }

  const jsonDataToString = (obj) => {
    // <-- JSON 데이터 받아서 파라미터에 넣을 형식으로 변환하기 -->
    return Object.keys(obj).map(key => `${key}:${obj[key]}`).join('▼');
  }

  const dateFilterButton = [
    {label: '당일', onClick: () => handleDateChange(today, today)},
    {label: '전일', onClick: () => handleDateChange(yesterday, yesterday)},
    {label: '일주일', onClick: () => handleDateChange(lastWeek, today)},
    {label: '한달', onClick: () => handleDateChange(lastMonth, today)},
  ]

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  function _getFormatDate() {
    let _startDate = neverLandUtils.utils.getDisplayTextByDate(startDate);
    let _endDate = neverLandUtils.utils.getDisplayTextByDate(endDate);
    return _startDate + '-' + _endDate;
  }

  useEffect(() => {
    getAllCustomerList(jsonDataToString(currentAlignData));
  }, [currentAlignData, currentPage]);

  function canAccessClientAccount() {
    // 고객 계정으로 로그인 제한
    if (accountInfo.getChainType().includes('watcher'))
      return false;
    return true;
  }

  const getAllCustomerList = (searchOrder) => {
    // <-- 모든 고객 리스트 정보 가져오기 -->
    let searchingCondition = `registDate:${_getFormatDate()}` + '▼' + searchCondition.code + ':' + searchConditionValue;

    let api = ALL_CUSTOMER_LIST_API;
    if (!canAccessClientAccount()) {
      api = ALL_CUSTOMER_LIST_API_EX;
    }
    getFetcher().get(accountInfo.makeGetRequest(api) + `&pageNumber=${currentPage}&pageSize=${pageSize}&searchCondition=${searchingCondition}&searchOrder=${searchOrder}`)
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            let datalist = result.allUserList.content;
            let currentPageNumber = result.allUserList.pageable.pageNumber + 1;
            if (datalist.length === 0) {
              currentPageNumber = 1;
            }
            setCustomerList(datalist);
            setCurrentPage(currentPageNumber);
            setTotalPage(result.allUserList.totalPages);
            setRefreshKey(new Date());
          } else {
            toast.error('데이터를 불러오는데 실패하였습니다.');
          }
        }).catch(err => toast.error(err.message));
  }

  const openModal = (customerData) => {
    setCustomerModalOpen(true);
    setCustomerInfo(customerData);
  }
  let index = 0;
  return (
      <>
        {customerModalOpen &&
            <AllCustomerManagementModal setCustomerModalOpen={setCustomerModalOpen} customerInfo={customerInfo}/>}
        <Sidebar/>
        <div className="lg:pl-64">
          <AdminPageHeader/>

          <main className="lg:mt-10 px-4 sm:px-6 lg:px-52 lg:py-12">
            <div className="sm:flex sm:items-center sm:py-0">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold leading-6 text-stone-900">전체고객관리</h1>
                <p className="mt-3 text-sm text-gray-400">
                  모디빅에 가입되어있는 모든 고객을 관리 합니다.
                </p>
              </div>
            </div>

            {/*데이트피커*/}
            <div className="text-sm my-5 flex flex-col sm:flex-row justify-start mt-5">
              <div className="flex flex-row items-center mb-3 md:mb-0">
                <span>등록일 :</span>
                <div className="ml-2 flex flex-row items-center">
                  {dateFilterButton.map((data) => (
                      <button
                          key={data.label}
                          onClick={data.onClick}
                          className="mr-2 flex flex-row rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                      >
                        {data.label}
                      </button>
                  ))}
                  <div
                      className="mr-3 flex flex-row justify-between px-5 py-2 ring-1 ring-gray-300 rounded-3xl text-center">
                    <DatePicker
                        locale={ko}
                        dateFormat='yyyy-MM-dd'
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        customInput={<DatePickerInput/>}
                    />
                    <span className="mx-2"> ~ </span>
                    <DatePicker
                        locale={ko}
                        dateFormat='yyyy-MM-dd'
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        customInput={<DatePickerInput/>}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <ListSelectBox listData={filterOption} onChange={(filter) => setSearchCondition(filter)}/>
                {searchCondition.code !== 'all' &&
                    <input className="mr-3 w-36 px-3 py-2 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500"
                           onChange={(e) => setSearchConditionValue(e.target.value)}
                           onKeyDown={(e) => {
                             if (e.key === 'Enter') getAllCustomerList();
                           }}
                    />
                }
                <button onClick={() => {
                  getAllCustomerList(jsonDataToString(currentAlignData))
                }}
                        className="whitespace-nowrap rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800">
                  조회
                </button>
              </div>
            </div>


            <table
                className="min-w-full divide-y divide-stone-300 ring-1 ring-gray-300 rounded-lg bg-stone-900">
              <thead>
              <tr className="whitespace-nowrap text-white font-semibold text-sm text-center">
                <th className="py-3">
                  {makeTableHeader("성명")}
                </th>
                <th className="hidden sm:table-cell">
                  {makeTableHeader("닉네임")}
                </th>
                <th className="hidden sm:table-cell">
                  {makeTableHeader("등록지점")}
                </th>
                <th>
                  {makeTableHeader("연락처")}
                </th>
                <th className="hidden sm:table-cell">
                  {makeTableHeader("이메일")}
                </th>
                <th className="hidden sm:table-cell">
                  {makeTableHeader("구분")}
                </th>
                <th className="hidden sm:table-cell">
                  {makeTableHeader("등록일")}
                </th>
                <th>
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-stone-300 bg-white" key={refreshKey}>
              {customerList?.map((data) => (
                  <tr key={data.userId} className="hover:bg-blue-50 whitespace-nowrap text-center text-sm">
                    <td className="py-3">
                      {data.userName}
                    </td>
                    <td className="hidden sm:table-cell">
                      {data.nickName}
                    </td>
                    <td className="flex justify-center">
                      {data.registStoreName &&
                          <p className={neverLandUtils.classNames(data.registStoreName.includes("ⓡ") ? "bg-blue-600" :
                                  (data.registStoreName.includes("ⓒ") ? "bg-green-600" : (data.registStoreName.includes("ⓓ") ? "bg-amber-500" : "bg-gray-500"))
                              , "text-white min-w-24 max-w-32 mt-2.5 px-3 py-0.5 rounded-lg font-semibold")}
                          >
                            {(data.registStoreName.endsWith("ⓡ") || data.registStoreName.endsWith("ⓒ") || data.registStoreName.endsWith("ⓓ")) ?
                                data.registStoreName.substring(0, data.registStoreName.length - 1) : data.registStoreName}
                          </p>
                      }
                    </td>
                    <td>
                      {data.phoneNumber}
                    </td>
                    <td className="hidden sm:table-cell">
                      {data.email}
                    </td>
                    <td className="hidden lg:table-cell">
                      {data.person}
                    </td>
                    <td className="hidden sm:table-cell">
                      {data.createDate.split('T')[0]}
                    </td>
                    <td>
                      <button
                          onClick={() => openModal(data)}
                          className="text-blue-600"
                      >
                        관리
                      </button>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>

            {/* 페이지네이션 */}
            {customerList !== null && customerList.length !== 0 && totalPage > 1 &&
                <div className="mt-10">
                  <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                  />
                </div>
            }
          </main>
        </div>
      </>
  )
}
export default AllCustomerManagementPage;