import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {RiAlarmWarningFill, RiCloseLine, RiExchangeBoxLine} from "react-icons/ri";
import {parseToJsonOrder} from "../../utils/textParsing";
import neverLandGameData from "../../utils/NeverLandGameData";
import neverLandUtils from "../../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {accountInfo} from "../../constants/constants";
import {getFetcher} from "../../utils/fetcher";
import {REGIST_NEW_GAME_TICKET_API} from "../../constants/api_constants";
import {MY_CART_PAGE, REQUEST_LIST_PAGE, STORE_REQUEST_LIST_PAGE} from "../../constants/page_constants";
import {useNavigate} from "react-router-dom";
import RequestModal from "./RequestModal";
import {customToast} from "../../utils/customToast";
import {getGameLogoImgURL} from "../Card/GameCard";
import HelperPopupModal from "./HelperPopupModal";


const TextToOrderModal = ({originText, refreshDate}) => {
  const [textToOrder, setTextToOrder] = useState(null);
  const [orderJson, setOrderJson] = useState(null);
  const navigator = useNavigate();
  const [requestModalOpen, setRequestModalOpen] = useState({modalOpen: false});

  let selected_ticket_data = null;

  let order_help_message_240624={
    title:"문자의뢰 방법",
    message:
        " 1. 입력칸에 의뢰할 내용 입력 .\n" +
        "                      기입 순서 : 경기번호 > 승무패/핸디캡/언오버 > 금액\n" +
        "                      \nex) 13승 19패 22승 5만 or  13승19패22패5만 (띄어쓰기 무관)\n" +
        "\n" +
        "                      2. 조합 사이에는 줄바꿈(enter)\n" +
        "\n" +
        "                      3. 입력 후 [재적용] 클릭\n" +
        "\n" +
        "                      4. 만들어진 문자의뢰 내용 확인 후 선택\n" +
        "\n" +
        "                      5. [카트담기] / [의뢰하기] 선택"+
        "\n\n※ 주의 사항 1. : 무승부가 없는 경기를 무승부를 하시면 안됩니다."+
        "\n\n※ 주의 사항 2. : 승무패와 언더오버는 별개로 취급됩니다. 승무패에 언더오버를 쓰시거나 언더오버에 승패로 쓰시면 안됩니다." +
        "\n\n※ 주의 사항 3. : 홀짝 역시 별개로 취급됩니다. (사용 예시 : 5홀 1천)"
  };

  function getPrediction(predicateText) {
    if (predicateText === '언더')
      return "승";
    if (predicateText === '오버')
      return "패";
    return predicateText.trim();
  }

  function makeSelectedTicketData(dataList) {
    if (dataList === null)
      return null;
    if (dataList.length === 0)
      return null;
    let _selected_ticket_data = [];
    let index = 0;
    let _thisTicket;
    for (let ticket of dataList) {
      if (ticket.error) {
        continue;
      }
      _thisTicket = {
        id: index++,
        amount: ticket.amount,
        ticketCount: 1,
        selectedGame: []
      };
      for (let game of ticket.selectedGame) {
        _thisTicket.selectedGame.push({
          allotId: game.allotCode,
          gameNumber: game.gameNo,
          prediction: getPrediction(game.predicateText)
        });
      }
      _selected_ticket_data.push(_thisTicket);
    }
    if (_selected_ticket_data.length === 0)
      return null;
    return _selected_ticket_data;
  }

  const registNewGameTickets = (type) => {
    // type = 'cart': 카트담기, 'print' : 출력의뢰
    if (selected_ticket_data === null || selected_ticket_data.length === 0) {
      toast.warning("선택한 티켓이 없습니다.");
      return;
    }
    console.log('registNewGameTickets->selected_ticket_data:', selected_ticket_data);

    let user = accountInfo.makeJson();
    let postJson = {
      user: user,
      list: [],
      targetStoreCode: '',
      defaultStatus: 'cart'
    };

    let ticket_data;
    let sent_ticket_list = [];

    for (let ticket of selected_ticket_data) {
      if (ticket.amount === 0)
        continue;
      ticket_data = {
        gameAllotCodesAndSelection: [],
        amount: ticket.amount,
        unitType: 'won',
        memo: '',
        ticketCount: ticket.ticketCount
      };
      sent_ticket_list.push(ticket.id);
      for (let game of ticket.selectedGame) {
        ticket_data.gameAllotCodesAndSelection.push({
          allotId: game.allotId, gameNo: game.gameNumber, selection: game.prediction
        });
      }
      postJson.list.push(ticket_data);
    }
    if (selected_ticket_data.length !== postJson.list.length) {
      toast.warning("금액을 입력해 주세요.");
      return;
    }
    if (type === 'print') {
      // 출력 의뢰 - 모달 띄우기
      setRequestModalOpen({modalOpen: true, postJson: postJson, sent_ticket_list: sent_ticket_list});
      return;
    }
    if (type === 'cart') {
      // 카트 담기
      getFetcher().post(REGIST_NEW_GAME_TICKET_API, postJson)
          .then(res => {
            const {type, message} = res.data.header;
            if (type === 'success') {
              toast.success(message);
              if (accountInfo.getUserConfig().isOn('on_route_page', true)) {
                navigator(MY_CART_PAGE);
              } else {
                closeModal();
              }
            } else {
              toast.error(message);
            }
          }).catch(err => {
        toast.error(err.message);
      })
      return;
    }
  }

  function requestPrintMethod(params) {
    // 출력의뢰
    let postJson = requestModalOpen.postJson;
    let sent_ticket_list = requestModalOpen.sent_ticket_list;

    postJson.defaultStatus = 'order';
    postJson.targetStoreCode = encodeURIComponent(params.selectedStore.data.code);
    postJson.customerUserIdForSpecialOrder = params.customerUserIdForSpecialOrder;

    let memo = '방문예정일시 : ' + params.visitTime + ') ' + params.requestMemo;
    for (let allot of postJson.list) {
      allot.memo = memo;
    }
    getFetcher().post(REGIST_NEW_GAME_TICKET_API, postJson)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            toast.success(message);
            if (accountInfo.getUserConfig().isOn('on_route_page', true)) {
              if (accountInfo.isStore()) {
                navigator(STORE_REQUEST_LIST_PAGE);
              } else {
                navigator(REQUEST_LIST_PAGE);
              }
            } else {
              closeModal();
            }
          } else {
            toast.error(message);
          }
        }).catch(err => {
      toast.error(err.message);
    })
    return;
  }


  function _refreshTextTrans(text) {
    let _textToOrder = text;
    if (!_textToOrder) {
      return;
    }
    let _tx = "";
    for (let i of _textToOrder.split('\n')) {
      if (i.length > 1) {
        if (i.indexOf('//') === 0) {
          continue;
        }
        if (_tx.length > 0) {
          _tx += "\n";
        }
        _tx += i;
      }
    }
    let jsonResult = parseToJsonOrder(_tx);

    setTextToOrder(jsonResult.orignInput);
    setOrderJson(jsonResult);
  }

  useEffect(() => {
    _refreshTextTrans(originText);
  }, [originText]);

  useEffect(() => {
    if (orderJson)
      _refreshTextTrans(textToOrder);
  }, [refreshDate]);

  const closeModal = () => {
    setTextToOrder(null);
    setOrderJson(null);
  }

  let dataList = orderJson && orderJson.orderData ? neverLandGameData.getTicketCardsByJsonOrder(orderJson.orderData) : [];
  let errDataCount = dataList.filter(data => data.error).length;
  selected_ticket_data = makeSelectedTicketData(dataList);

  function registNewGameTicketsWithValidation(cart, errDataCount) {
    if (errDataCount === 0) {
      registNewGameTickets(cart);
      return;
    }
    customToast.info({
      title: "오류가 포함되어 있는 문자의뢰",
      message: "문자의뢰에 오류 건이 [" + errDataCount + "] 건 포함되어있습니다.\n\n 무시하고 처리하시겠습니까?",
      button: ["오류건을 무시하고 처리", "중지"],
      onClick: (name) => {
        if (name === "오류건을 무시하고 처리") {
          registNewGameTickets(cart);
        }
      }
    });
  }

  return (
      <div>
        {requestModalOpen.modalOpen &&
            <RequestModal requestMethod={requestPrintMethod} requestModalData={requestModalOpen}
                          setRequestModalOpen={setRequestModalOpen}/>
        }

        <Transition.Root show={orderJson !== null} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            {orderJson !== null && <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                      className="relative transform rounded-lg bg-white px-3 py-5 text-left shadow-xl transition-all w-80 md:w-[35rem] 2xl-only:w-[50rem] mt-20">

                    <div className="mx-2">
                      <div>
                        <table className="w-full mb-5">
                          <tr>
                            <td>
                              <div className="flex flex-row justify-center items-center">
                                <h3 className="ml-6 md:ml-20 text-xl text-center font-semibold leading-6 text-gray-900">
                                  문자의뢰 등록하기
                                </h3>
                                <HelperPopupModal message={order_help_message_240624}/>
                              </div>
                            </td>
                            <td>
                              <div className="flex justify-end">
                                <button onClick={closeModal}>
                                  <RiCloseLine className="w-5 h-5"/>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="flex flex-col flex-wrap">

                        <div className="mt-3">
                          {/* 경기카드 영역 */}
                          {dataList.length > 0 &&
                              <div
                                  className="grid grid-cols-1 md:grid-cols-2 2xl-only:grid-cols-3 gap-2 max-h-[25rem] overflow-y-auto font-semibold">
                                {dataList.map((data, index) =>
                                    (
                                        <div
                                            className={neverLandUtils.classNames(data.error ? "bg-rose-100 border-rose-600" : "bg-white border-orange-600",
                                                "rounded-lg p-2 max-w-[15rem] border-2")}>
                                          <div className="flex flex-row items-center">
                                          <span
                                              className="h-6 w-6 text-sm text-white bg-orange-600 rounded-full mr-1 px-1.5 py-0.5">{index + 1}</span>
                                            {data.error &&
                                                <div className="flex flex-row text-white items-center bg-rose-600 px-1">
                                                  <RiAlarmWarningFill className="h-4 w-auto mr-1"/>
                                                  <p>{data.error}</p>
                                                </div>
                                            }
                                          </div>

                                          <div className="text-sm flex flex-row items-center my-1.5">
                                            <p>금액 : {data.amount.toLocaleString()}원</p>
                                            <p className="bg-gray-600 text-white ml-2 rounded-md px-2"> {data.selectedGame.length} 폴더</p>
                                          </div>

                                          {data.selectedGame.map((item) => {
                                            let isErrorPart = item.predicateText.includes("오류") || item.rate === 0;
                                            if (!item.title.includes("발매중")) {
                                              isErrorPart = true;
                                            }
                                            if (item.title.includes(" X")) {
                                              isErrorPart = true;
                                            }
                                            // 타이틀 쪼개기 (경기 카테고리 텍스트 -> 이미지로 대체 하기 위해서..)
                                            let [part1, part2] = item.title.split('|');
                                            let [status, prefix] = part1.split(')');
                                            let trimmedStatus = status?.trim() + ' )';
                                            let trimmedCategory = prefix?.trim();
                                            let match = part2?.trim();
                                            return (
                                                <div
                                                    className={neverLandUtils.classNames(isErrorPart ? "border-white text-white bg-rose-700"
                                                        : "border-orange-300", "rounded-md  border-2 p-1 mb-1.5 max-w-[14rem]")}>
                                                  <div className="text-xs flex flex-row items-center">
                                                    <p>{trimmedStatus}</p>
                                                    {trimmedCategory&&<img src={getGameLogoImgURL(trimmedCategory)} alt="cate_image"
                                                         className={neverLandUtils.classNames(trimmedCategory === 'BS' ? "h-6 w-6" : "h-5 w-5 mx-1")}/>}
                                                    {match?.replace(" X", "")}
                                                  </div>
                                                  <div>{item.gameNo.replace("⒮", "S")} | {item.predicateText} {item.rate}</div>
                                                </div>
                                            )
                                          })
                                          }
                                        </div>
                                    )
                                )}
                              </div>
                          }

                          {/*문자내용 입력 영역*/}
                          <div className="flex flex-col 2xl-only:flex-row mt-5 justify-center">
                            <div
                                className="ring-2 ring-sky-700 rounded-2xl px-3 py-2.5 mr-3 w-64 md:w-full 2xl-only:w-96 h-44">
                                <textarea
                                    value={textToOrder}
                                    className="w-60 md:w-full 2xl-only:w-[23rem] h-40"
                                    onChange={(e) => setTextToOrder(e.target.value)}
                                />
                            </div>

                            <div className="flex flex-col lg:flex-row md:mt-3 2xl-only:mt-0">
                              <div className="flex flex-row items-center my-2 justify-center">
                                <button onClick={() => _refreshTextTrans(textToOrder)}
                                        className="flex flex-row bg-sky-700 px-3 py-1 items-center text-white rounded-lg mb-1 mr-3 whitespace-nowrap"
                                >
                                  <RiExchangeBoxLine className="h-5 w-auto mr-1"/>
                                  <p>재적용</p>
                                </button>
                              </div>

                              <div
                                  className="border border-gray-300 bg-gray-100 rounded-lg text-sm w-64 md:w-full 2xl-only:w-60 p-1.5">
                                {orderJson && orderJson.orderData &&
                                    <div className="h-36 md:h-40 w-60 md:w-full lg:w-56 overflow-y-auto pl-2 py-1">
                                      {orderJson.orderData.map((data) => {
                                        return data.error ? (<div>{data.error}</div>) : (
                                            <div>{data.amount}원 - {data.itemCount}폴더</div>)
                                      })
                                      }
                                    </div>
                                }
                              </div>
                            </div>
                          </div>

                          {/* 실행 버튼 라인 */}
                          <div className="flex flex-col md:flex-row mt-3 justify-end items-center">
                            {errDataCount > 0 ?
                                <p className="mr-3 font-semibold text-rose-600">총 {dataList.length} 건 /
                                  오류 {errDataCount} 건 </p> :
                                <p className="mr-3 font-semibold">총 {dataList.length} 건 / </p>}
                            <div className="flex flex-row mt-2 md:mt-0">
                              <button onClick={() => registNewGameTicketsWithValidation('cart', errDataCount)}
                                      className="mr-3 bg-green-600 px-2 text-white rounded-lg py-1 font-semibold">카트담기
                              </button>
                              <button onClick={() => registNewGameTicketsWithValidation('print', errDataCount)}
                                      className="bg-rose-600 px-2 text-white rounded-lg py-1 font-semibold">의뢰하기
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
            }
          </Dialog>
        </Transition.Root>
      </div>
  )
}
export default TextToOrderModal;
