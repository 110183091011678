import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {RiCloseLine} from "react-icons/ri";
import {getFetcher} from "../../utils/fetcher";
import {ALL_SERVICE_LIST_SIMPLE_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/locale";
import DatePickerInput from "../../components/DatePickerInput";
import {accountInfo} from "../../constants/constants";

const SelectServiceModal = ({selectedServiceList, setSelectedServiceList, setShowSelectServiceModal}) => {
  const [open, setOpen] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [allServiceList, setAllServiceList] = useState([]); // 모든 서비스 리스트
  const [filteredServiceList, setFilteredServiceList] = useState(allServiceList);

  const closeModal = () => {
    setOpen(false);
    setShowSelectServiceModal(false);
  }

  useEffect(() => {
    getServiceList();
  }, []);

  useEffect(() => {
    let _searchText = searchText.toLowerCase().trim().replace(" ", "");
    // 검색어와 일치하는 리스트 반환
    if (_searchText === '') {
      setFilteredServiceList(allServiceList);
    } else {
      setFilteredServiceList(
          allServiceList.filter((service) =>
              service.serviceName.toLowerCase().replace(" ", "").trim().includes(_searchText))
      );
    }
  }, [searchText, allServiceList]);

  const getServiceList = () => {
    let date_=new Date().getTime();
    // <-- 리스트 정보 가져오기 -->
    // getFetcher().get(ALL_SERVICE_LIST_SIMPLE_API+"?userId="+accountInfo.accountId())
    getFetcher().get(ALL_SERVICE_LIST_SIMPLE_API)
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            console.log('time-delay:getServiceList:'+(new Date().getTime()-date_));
            setAllServiceList(result.list.sort((d1, d2) => {
              // 이름 정렬
              return d1.serviceName.localeCompare(d2.serviceName);
            }))
          }
        }).catch(err => toast.error(err.message));
  }

  const handleSelectService = (serviceCode) => {
    // <-- 서비스 체크박스 핸들링 -->
    setSelectedServiceList((prevList) => {
      let _findItem = prevList.find((item) => item.serviceCode === serviceCode);
      if (_findItem !== undefined) {
        // 이미 선택되어있으면 빼기
        return prevList.filter(item => item !== _findItem);
      } else {
        let _findItem = allServiceList.find((item) => item.serviceCode === serviceCode);

        if (_findItem !== undefined) {
          // 선택 안된 서비스이면 추가
          return [...prevList, _findItem].sort((d1, d2) => {
            // 이름 정렬
            return d1.serviceName.localeCompare(d2.serviceName);
          });
        }
        return prevList;
      }
    });
  };

  const removeSelectService = (service) => {
    // <-- 서비스 선택해제 -->
    setSelectedServiceList((prevList) => prevList.filter(item => item.serviceCode !== service.serviceCode))
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">

                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-xl font-semibold leading-6 text-gray-900"> 서비스 선택 </h3>
                    <p className="text-sm mt-2 text-blue-600">※ 하나라도 선택하지 않으면, 모든 서비스으로 간주됩니다. </p>
                  </div>

                  <div className="flex flex-col justify-center mt-3">
                    <div className="flex flex-row">
                      <input
                          onChange={(e) => setSearchText(e.target.value)}
                          onFocus={(e) => e.target.select()}
                          spellCheck="false"
                          value={searchText}
                          className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="flex flex-row flex-wrap my-2">
                      {/*선택된 서비스들*/}
                      {selectedServiceList.length === 0 ?
                          <span
                              className="my-1 mr-2 flex flex-row justify-center rounded-md bg-blue-300 font-semibold px-1.5 py-1 text-sm shadow-sm whitespace-nowrap items-center">
                            전체 서비스
                          </span> :
                          selectedServiceList.map((service) => {
                            return (
                                <button
                                    onClick={() => removeSelectService(service)}
                                    className="my-1 mr-2 flex flex-row justify-center rounded-md bg-yellow-300 font-semibold px-1.5 py-1 text-sm shadow-sm whitespace-nowrap items-center"
                                >
                                  <p className="text-sm">{service.serviceName}</p>
                                  <RiCloseLine className="ml-1 h-4 w-4 text-black"/>
                                </button>
                            )
                          })}
                    </div>

                    <div className="flex flex-col justify-between mt-1 max-h-52 overflow-y-scroll">
                      {/*전체 서비스 리스트*/}
                      {filteredServiceList.map((service) => {
                        return (
                            <div className="flex flex-row items-center mb-2 cursor-pointer"
                                 onClick={() => handleSelectService(service.serviceCode)}>
                              <input type="checkbox"
                                     checked={selectedServiceList.find((item) => item.serviceCode === service.serviceCode) !== undefined}
                                     className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                              />
                              <p className="text-sm"> {service.serviceName} </p>&nbsp;
                              <p className="text-xs text-gray-600"> ({service.serviceCode})</p>
                            </div>
                        )
                      })}
                    </div>

                    <div className="flex flex-row items-center justify-end mt-2">
                      <button
                          onClick={closeModal}
                          className="ml-2 inline-flex justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 whitespace-nowrap"
                      >
                        완료
                      </button>
                    </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default SelectServiceModal;
