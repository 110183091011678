import Sidebar from "../../components/Sidebar";
import MyStoreHeader from "../../components/Header/MyStoreHeader";
import {accountInfo, getLogoImage} from "../../constants/constants";
import React, {useEffect, useState} from "react";
import {getFetcher} from "../../utils/fetcher";
import {EDIT_MY_STORE_INFO_API, GET_MY_STORE_INFO_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import neverLandUtils from "../../utils/NeverLandUtils";
import neverLandGameData from "../../utils/NeverLandGameData";
import {MAIN_PAGE} from "../../constants/page_constants";
import {useNavigate} from "react-router-dom";

const MyStoreInfoPage = () => {
  const [storeName, setStoreName] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [storePhoneNumber, setStorePhoneNumber] = useState('');
  const [myStoreInfo, setMyStoreInfo] = useState({});

  useEffect(() => {
    getStoreInfo()
  }, []);


  const getStoreInfo = () => {
    // <-- 지점 정보 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(GET_MY_STORE_INFO_API))
        .then(res => {
          // 계정 차단 여부 확인
          if(neverLandUtils.security.checkLogout(res)){
            return;
          }
          const {storeName, storeCode, address, phoneNumber} = res.data.result.storeInfoForOwner;
          setStoreName(storeName);
          setStoreCode(storeCode);
          setStoreAddress(address);
          setStorePhoneNumber(phoneNumber);
          setMyStoreInfo(res.data.result.storeInfoForOwner);
        }).catch(err => toast.error(err.message));
  }
  const editMyStoreInfo = () => {
    // <-- 지점 정보 변경 -->
    getFetcher().post(EDIT_MY_STORE_INFO_API, {
      userId: accountInfo.accountId(),
      address: storeAddress,
      phoneNumber: storePhoneNumber
    }).then(res => {
      const header = res.data.header;
      if (header.type === "success") {
        toast.success("변경이 완료 되었습니다.")
        getStoreInfo();
      } else {
        toast.error("변경이 실패하였습니다.")
      }
    }).catch(err => toast.error(err.message))
  }

  return (
      <>
        <div>
          <Sidebar/>
          <div className="lg:pl-64">
            <MyStoreHeader/>
            <main>
              <div className="lg:mt-6 divide-y divide-gray-300">
                <div className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div className="sm:ml-10">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900">지점정보</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      지점정보 조회
                    </p>
                  </div>

                  <div className="md:col-span-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full flex items-center gap-x-8">
                        <img src={getLogoImage('whaleLogoBlue')} alt="WhaleLogo"
                             className="h-20 w-auto flex-none object-cover"/>
                        <div>
                          <p className="text-xl font-semibold leading-7 text-gray-900"> {storeName} </p>
                          {/*<p className="mt-2 text-xs leading-5 text-gray-400">앱푸시 알람설정</p>*/}
                        </div>
                      </div>

                      <div className="col-span-full mt-4">
                        <label htmlFor="nickname" className="block text-sm font-semibold leading-6 text-gray-900">
                          지점코드
                        </label>
                        <div className="mt-2 flex flex-row items-center">
                          <input
                              value={storeCode || ''}
                              spellCheck="false"
                              disabled={true}
                              className="block w-52 rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 text-sm sm:leading-6"
                          />
                          <button className="ml-2 border border-gray-300 rounded-md px-1 py-1 text-sm font-semibold">
                            <p onClick={() => {navigator.clipboard.writeText(storeCode); toast.success("지점코드["+storeCode+"]가 복사되었습니다.")}}
                               className="text-xs font-semibold text-gray-500"
                            > 복사하기
                            </p>
                          </button>
                          {/*<button*/}
                          {/*    className="ml-3 rounded-md bg-white ring-1 ring-gray-300 text-gray-500 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"*/}
                          {/*>*/}
                          {/*  재발급 요청*/}
                          {/*</button>*/}
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                          지점주소
                        </label>
                        <div className="mt-2 flex flex-row items-center">
                          <input
                              value={storeAddress || ''}
                              onChange={(e) => setStoreAddress(e.target.value)}
                              className={neverLandUtils.classNames(myStoreInfo.address === storeAddress ? "text-gray-900" : "font-semibold text-orange-500",
                                  "block w-80 rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset ring-gray-300 text-sm")}
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                          연락처
                        </label>
                        <div className="mt-2 flex flex-row items-center">
                          <input
                              value={storePhoneNumber || ''}
                              onChange={(e) => setStorePhoneNumber(e.target.value)}
                              className={neverLandUtils.classNames(myStoreInfo.phoneNumber === storePhoneNumber ? "text-gray-900" : "font-semibold text-orange-500",
                                  "block w-80 rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset ring-gray-300 text-sm")}
                          />
                        </div>
                      </div>

                      <div className="flex">
                        <button
                            onClick={editMyStoreInfo}
                            disabled={myStoreInfo.phoneNumber === storePhoneNumber && myStoreInfo.address === storeAddress}
                            className={neverLandUtils.classNames((myStoreInfo.phoneNumber === storePhoneNumber && myStoreInfo.address === storeAddress) ?
                                "bg-gray-300" : "bg-sky-600 hover:bg-sky-500", "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm whitespace-nowrap"
                            )}
                        >
                          지점 정보 수정
                        </button>
                      </div>

                      {accountInfo.isExtServiceUsable('bona') && accountInfo.getStoreLevel() === "2" &&
                          <div
                              className="col-span-full mt-3 flex flex-row text-base font-semibold text-gray-500">
                            <p> {accountInfo.getExtServiceName('bona')} </p>
                            <p className="ml-5"> {accountInfo.extServiceAccount('bona')} </p>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
  )
}
export default MyStoreInfoPage;