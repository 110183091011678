import {EnvelopeIcon, LockClosedIcon} from "@heroicons/react/16/solid";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {MAIN_PAGE, SIGNUP_PAGE} from "../constants/page_constants";
import {toast} from "react-toastify";
import neverLandUtils from "../utils/NeverLandUtils";
import {getFetcher} from "../utils/fetcher";
import {LOGIN_API} from "../constants/api_constants";
import {accountInfo, getLogoImage} from "../constants/constants";
import SendTempPasswordModal from "../components/Modal/SendTempPasswordModal";
import {RiCloseCircleFill, RiEyeFill, RiEyeOffFill} from "react-icons/ri";


const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showTempPasswordModal, setShowTempPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const login = () => {
    // 로그인
    if (!email || !password)
      return toast.error("이메일, 비밀번호 모두 입력하세요");
    // 비밀번호 암호화해서 넘기기
    const encryptPassword = neverLandUtils.security.encrypt(password);

    getFetcher().post(LOGIN_API, {
      email: email.toLowerCase(),
      // // 임시
      serviceCode: process.env.REACT_APP_SERVICE_CODE === undefined ? "null" : process.env.REACT_APP_SERVICE_CODE,
      password: encryptPassword
    })
        .then(res => {
          const {type, message} = res.data.header;
          console.log(res);
          if (type === 'fail') {
            toast.error(message);
            return;
          }
          if (type === 'success') {
            toast.success(message);
            let userInfo = res.data.result.user;
            accountInfo.login(userInfo);
            navigate(MAIN_PAGE);
          }
        })
        .catch((err) => toast.error(err));
  }

  const createTempPassword = () => {
    if (!email) {
      toast.warning('이메일을 입력하시면 임시비밀번호를 발급해드립니다.')
    } else {
      setShowTempPasswordModal(true);
    }
  }

  return (
      <>
        {showTempPasswordModal &&
            <SendTempPasswordModal email={email} setShowTempPasswordModal={setShowTempPasswordModal}/>}

        <div className="flex min-h-screen flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img src={getLogoImage('whaleLogoBlue')} alt="WhaleLogo" className="mx-auto h-20 w-auto"/>
            <img src={getLogoImage('textLogoBlue')} alt="MobydickLogo" className="mx-auto h-7 w-46 mt-4"/>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-3">

              <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <EnvelopeIcon className="h-5 w-5 text-gray-400"/>
                </div>
                <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="이메일"
                    required
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                <RiCloseCircleFill className="cursor-pointer absolute px-3 text-gray-400 right-0 h-4 w-auto"
                                   onClick={() => setEmail('')}/>
              </div>

              <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-gray-400"/>
                </div>
                <input
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') login();
                    }}
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="비밀번호"
                    required
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="absolute px-3 text-gray-400 right-0">
                  {showPassword ?
                      <RiEyeFill className="cursor-pointer h-5 w-auto"
                                 onClick={() => setShowPassword(!showPassword)}/> :
                      <RiEyeOffFill className="cursor-pointer h-5 w-auto"
                                    onClick={() => setShowPassword(!showPassword)}/>
                  }
                </div>
              </div>

              <div>
                <button
                    onClick={login}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  로그인
                </button>
              </div>
            </div>

            <Link to={MAIN_PAGE}>
              <p className="mt-5 text-center text-sm text-gray-500 underline"> 비회원으로 이용하기 </p>
            </Link>

            <div className="mt-5 text-sm text-gray-500 flex flex-col sm:flex-row items-center justify-center">
              {/*<div>*/}
              {/*  <button className="text-sm text-center"*/}
              {/*          onClick={createTempPassword}*/}
              {/*  >*/}
              {/*    <p>비밀번호를 잊으셨나요?</p>*/}
              {/*  </button>*/}
              {/*</div>*/}
              {/*<p className="mx-2 hidden sm:block"> / </p>*/}
              <div className="flex flex-row text-center items-center mt-2 sm:mt-0">
                <p>아직 회원이 아니신가요?</p>
                <Link to={SIGNUP_PAGE} className="ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  회원가입
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default LoginPage;